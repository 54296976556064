import { useCallBackend } from '../General/CallBackend';
import {log} from '../../Log';
import calculatorTest from '../../resources/test/calculatorTest.json';

export function CallOldCalculator (input,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';
    log.debug("send JSON CallCalculator - CallOldCalculator");

    if (testMode) {
        log.debug(input);
        //responseFunc ({err : "error.server_return_error" });
        responseFunc ({netPrice: 10,excise: 2,specialExcise: 3,packageTax: 1,commision: 4,VAT: 5,totalPrice: 25});
    } else {
        const body = {
            country: input.country,
            productType: input.productType,
            degrees: input.degrees,
            plato: !input.plato || input.plato==="" ? '0' : input.plato,
            volume: input.volume,
            packageType: input.packageType,
            annualProduction: !input.annualProduction || input.annualProduction==="" ? '0' : input.annualProduction,
            netPrice: input.netPrice==="" ? '0' : input.netPrice,
            totalPrice: input.totalPrice==="" ? '0' : input.totalPrice
        }

        log.debug(body);
        log.debug("post");

        CallBackend ("calculator",body, response => {
            responseFunc(response);
        });
    }
}
export function CallAdminCalculator (login,input,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';
    log.debug("send JSON CallCalculator - CallAdminCalculator");

    const body = { 
        req : {...login,action: 2},
        ...input
    }
    log.debug(body);

    if (testMode) {
        responseFunc (calculatorTest.calculatorResponse);
    } else {
        CallBackend ("calculator",body, response => {
            responseFunc(response);
        });
    }
}