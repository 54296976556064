import React from 'react';
import TableHeaderCell from './TableHeaderCell';
import settings from '../../../resources/generalSettings.json'
import PropTypes from 'prop-types';

TableHeader.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string)
};

export default function TableHeader(props) {
    const {columns} = props;

    return (
        <thead className={settings.tableHeaderClassName}>
            <tr>
                {columns.map((element) => (
                    <TableHeaderCell key={element} label={element} />
                ))}
            </tr>
        </thead>
    );
}
