import React, { useContext,useEffect,useState,useImperativeHandle,forwardRef } from 'react'
import { useTranslation } from "react-i18next";
import { AppContext } from '../Drinkereez';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
import TableLayout from '../layout/tables/TableLayout';
import TableRowCell from '../layout/tables/TableRowCell';
import resources from '../../resources/lists/dropdownlist.json';
import { GetLabelFromList,removePublicPrefixURL } from '../GeneralFunc';
import { CallGetAllClientServices } from './CallClient';
import FormLineErrorMessage from '../layout/forms/FormLineErrorMessage';
import {log} from '../../Log';
import PropTypes from 'prop-types';
import FormLineSelect from '../layout/forms/FormLineSelect';

const ClientServicesListAdmin = forwardRef(({ showService }, ref) => {
    const { t } = useTranslation();
    const { login } = useContext(AppContext);
    const columns = ["label.client","label.service","label.motivation","label.contract","label.state"];
    const [clientServices,setClientServices] = useState ();
    const [filterOption,setFilterOption] = useState({state:"DRINKEREEZ_SERVICE_STATE_REQUESTED"});
    const [error, setError] = useState({errorMessage: '',errorJSON: {}});

    useEffect(() => {
        callGetAllClientServices();
      }, [login]);

    function callGetAllClientServices () {
        CallGetAllClientServices(login,response => {
            if (response.err) {
                setError({errorMessage: response.err, errorJSON:{login}});
                return;
            } else {
                setClientServices(response.clientServices); 
            }
        })
    }

    function openContract (clientService) {
        window.open(process.env.REACT_APP_FILEURL + removePublicPrefixURL(clientService.contract.url), '_blank')
    }

    const changeHandler = e => {
        log.debug(e);
        setFilterOption({...filterOption,[e.name] : e.value});
    };

    useImperativeHandle(ref, () => ({
        reloadData: callGetAllClientServices
    }));

    function displayData () {
        if (clientServices) {
            return clientServices.map((clientService) => {
                if (clientService.state !== filterOption.state) {
                    return null; // Skip rendering this row if the condition is not met
                }
                return ( 
                    <tr key={clientService.id}>
                        <TableRowCell value={clientService.client.company.name} onClick={() => showService(clientService)} />
                        <TableRowCell label={GetLabelFromList(resources.drinkereezServices, clientService.type)} />
                        <TableRowCell label={clientService.motivation} />
                        <TableRowCell label={clientService.contract.name} onClick={() => openContract (clientService)} />
                        <TableRowCell label={GetLabelFromList(resources.drinkereezServiceStates, clientService.state)} />
                    </tr>
                );
            });
        }
    }

    return (
        <div>
            <div className='row'>
                <FormLineParagHeader col={8} label={"title.servicesList"}/>
                <FormLineSelect col={4} label="label.state" list={resources.drinkereezServiceStates} name="state" value={filterOption.state} onChange={changeHandler}/>
            </div>
            <FormLineErrorMessage message={error.errorMessage} errorJSON={error.errorJSON}/>
            <TableLayout columns={columns} displayData={displayData}/>
        </div>
    )
})

ClientServicesListAdmin.propTypes = {
    showService: PropTypes.func.isRequired // Expecting a function type
};

export default ClientServicesListAdmin;