import React,{useState} from 'react';
//import { AppContext } from '../Drinkereez';
import UploadXML from './UploadXML';
import ShowXMLs from './ShowXMLs';
import ConsultXML from './ConsultXML';
import ShowIE801 from '../Excise/ShowIE801';

export default function ExcisePage() {
    const [xmlData,setXmlData] = useState();
    const [showXml,setShowXml] = useState('LIST');
    const [exciseDoc,setExciseDoc] = useState(); 

    function xmlSelected (xml) {
        setShowXml('CONS');
        setXmlData(xml);       
    }

    function stopShowXml () {
        setShowXml('LIST');
    }

    function xmlSaved () {
        stopShowXml();
    }

    function addXML () {
        setXmlData({name: "", type : "", xml: ""});
        setShowXml('ADD');
    }

    function showDoc (doc) {
        setExciseDoc(doc);
        setShowXml('DOC');
    }

    return (
        <div className="ShowXML">
            {
                showXml==='LIST' && <ShowXMLs xmlSelected={xmlSelected} addXML={addXML}/>
            }
            {
                showXml==='ADD' && <UploadXML xmlData={xmlData} setXmlData={setXmlData} stopShowXml={stopShowXml} xmlSaved={xmlSaved}/>
            }
            {
                showXml==='CONS' && <ConsultXML xmlData={xmlData} stopShowXml={stopShowXml} showDoc={showDoc}/>
            }
            {
                showXml==='DOC' && <ShowIE801 exciseDoc={exciseDoc} cancelViewDoc={stopShowXml}/>
            }
        </div>
    )
}