import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AppContext } from './Drinkereez';

function ProtectedRoute({ allowedRoles }) {
    const { user, login, client } = useContext(AppContext);

    const isUserAuthenticated = user && login;
    const isClientAuthenticated = isUserAuthenticated && client;

    if (!isUserAuthenticated) {
        return <Navigate to="/login" />;
    }

    if (allowedRoles.includes('USER_TYPE_CLIENTADMIN') && !isClientAuthenticated) {
        return <Navigate to="/login" />;
    }

    if (allowedRoles.includes(user.userType)) {
        return <Outlet />;
    }

    return <Navigate to="/login" />;
}

export default ProtectedRoute;
