import React, {useContext,useEffect,useState} from 'react'
import { getRecordsByVariableName,orderByField } from '../GeneralFunc';
import { AppContext } from '../Drinkereez';
import {log} from '../../Log';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
import { CallSaveNationalCode} from './CallNationalCode';
import TableLayout from '../layout/tables/TableLayout';
import FormButton from '../layout/forms/FormButton';
import resources from '../../resources/lists/dropdownlist.json'
import TableRowCellInput from '../layout/tables/TableRowCellInput';
import TableRowCellSelect from '../layout/tables/TableRowCellSelect';
import TableRowCellFromTill from '../layout/tables/TableRowCellFromTill';
import TableRowCellButton from '../layout/tables/TableRowCellButton';
import FormLineSelect from '../layout/forms/FormLineSelect';
import PropTypes from 'prop-types';

ShowNationalCodes.propTypes = {
    nationalCodes: PropTypes.any, // Replace 'any' with the specific type you expect
    nationalCodeSelected: PropTypes.any, // Replace 'any' with the specific type you expect
    addOrUpdateNatCode: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function ShowNationalCodes(props) {
    const {nationalCodes,nationalCodeSelected,addOrUpdateNatCode} = props;
    const {login} = useContext(AppContext);
    const [searchOptions,setSearchOptions] = useState ({productType:"",sparklingType:"",packageType:"",abvYesNo:"true",breweryYesNo:"true"});
    const [viewNationalCodes,setViewNationalCodes] = useState();
    const [selectedNationalCode,setSelectedNationalCode] = useState ();
    const [forceRender, setForceRender] = useState(false); // eslint-disable-line no-unused-vars

    useEffect(() => { 
        log.debug("viewNationalCodes updated");
        setViewList(nationalCodes);
        setForceRender(prevValue => !prevValue); 
    },[searchOptions,nationalCodes]);

    function onClickTable (e) {
        nationalCodeSelected(nationalCodes.find(({id}) => id === e.target.id));
    }

    function editHandler (e) {
        log.debug("in ShowNationalCode - editHandler");
        setSelectedNationalCode(nationalCodes.find(({id}) => id === e.currentTarget.id));
    }

    function saveHandler () {
        log.debug("in ShowNationalCode - saveHandler");

        CallSaveNationalCode(login,selectedNationalCode,response => {
            if (response.err || response.ret?.err) {
                log.debug("error");
                //setError({errorMessage: response.err + ' ' + response.ret?.err, errorJSON: {login,selectedNationalCode}});
                return;
            }
            log.debug(response);
            setSelectedNationalCode();
            addOrUpdateNatCode(response);
        });
    }

    function addHandler () {
        log.debug("in ShowNationalCode - addHandler");
        if (!selectedNationalCode)
            setSelectedNationalCode({id:"0",country:"BE",natCode:"",productType:searchOptions.productType,sparklingType:searchOptions.sparklingType,
                                    packageType:searchOptions.packageType,abvFrom:searchOptions.abvYesNo==="true"?"":-1,abvTo:searchOptions.abvYesNo==="true"?"":-1,
                                    productionHLFrom:searchOptions.breweryYesNo==="true"?"":-1,productionHLTo:searchOptions.breweryYesNo==="true"?"":-1});
    }

    function clearHandler () {
        log.debug("in ShowNationalCode - clearHandler");
        setSearchOptions({productType:"",sparklingType:"",packageType:"",abvYesNo:"true",breweryYesNo:"true"});
    }

    function changeHandler (e) {
        log.debug("e " + e.id + " " + e.name + " " + e.value);
        if (e.id===selectedNationalCode.id)
            setSelectedNationalCode({...selectedNationalCode,[e.name]: e.value});
    }

    function changeSearchHandler (e) {
        setSearchOptions({...searchOptions,[e.name]: e.value});
    }

    function diplayRow (nationalCode) {
        const id = nationalCode.id;
        const natCode = (selectedNationalCode?.id===id) ? selectedNationalCode.natCode : nationalCode.natCode;
        const productType = (selectedNationalCode?.id===id) ? selectedNationalCode.productType : nationalCode.productType;
        const sparklingType = (selectedNationalCode?.id===id) ? selectedNationalCode.sparklingType : nationalCode.sparklingType;
        const packageType = (selectedNationalCode?.id===id) ? selectedNationalCode.packageType : nationalCode.packageType;
        const abvFrom = (selectedNationalCode?.id===id) ? selectedNationalCode.abvFrom : nationalCode.abvFrom;
        const abvTo = (selectedNationalCode?.id===id) ? selectedNationalCode.abvTo : nationalCode.abvTo;
        const productionHLFrom = (selectedNationalCode?.id===id) ? selectedNationalCode.productionHLFrom : nationalCode.productionHLFrom;
        const productionHLTo = (selectedNationalCode?.id===id) ? selectedNationalCode.productionHLTo : nationalCode.productionHLTo;
        const readOnly = (selectedNationalCode?.id===nationalCode.id) ? false : true;
        const oneSelected = (selectedNationalCode) ? true : false;
        return (
            <tr key={nationalCode.id}>
                <TableRowCellInput name="natCode" value={natCode} id={id} onClick={onClickTable} readOnly={readOnly} onChange={changeHandler}/>
                {
                    (!(searchOptions?.productType!=="")) && <TableRowCellSelect id={id} list={resources.generalProductTypes} name="productType" value={productType} onChange={changeHandler} label="label.productType" readOnly={readOnly}/>
                }
                {
                    (!(searchOptions?.sparklingType!=="")) && <TableRowCellSelect id={id} list={resources.sparklingTypes} name="sparklingType" value={sparklingType} onChange={changeHandler} label="label.sparklingType" readOnly={readOnly}/>
                }
                {
                    (!(searchOptions?.packageType!=="")) && <TableRowCellSelect id={id} list={resources.packageTypes} name="packageType" value={packageType} onChange={changeHandler} label="label.packageType"readOnly={readOnly}/>
                }
                {
                    (searchOptions?.abvYesNo==="true") && <TableRowCellFromTill id={id} nameFrom="abvFrom" nameTo="abvTo" valueFrom={abvFrom} valueTo={abvTo} readOnly={readOnly} onChange={changeHandler}/>
                }
                {
                    (searchOptions?.breweryYesNo==="true") && <TableRowCellFromTill id={id} nameFrom="productionHLFrom" nameTo="productionHLTo" valueFrom={productionHLFrom} valueTo={productionHLTo} readOnly={readOnly} onChange={changeHandler}/>
                }
                {
                    !oneSelected && readOnly && <TableRowCellButton id={id} onClick={editHandler} label="button.edit"/>
                }
                {
                    !readOnly && <TableRowCellButton id={id} onClick={saveHandler} label="button.save"/>
                }
            </tr>
        )
    }

    function setViewList(natCs) {
        log.debug("in setViewList");
        if (!natCs) return;
        var natCodes = natCs;
        if (searchOptions?.productType!=="") natCodes = getRecordsByVariableName(natCodes,"productType",searchOptions.productType);
        if (searchOptions?.sparklingType!=="") natCodes = getRecordsByVariableName(natCodes,"sparklingType",searchOptions.sparklingType);
        if (searchOptions?.packageType!=="") natCodes = getRecordsByVariableName(natCodes,"packageType",searchOptions.packageType);
        setViewNationalCodes(orderByField(natCodes,"natCode"));
    }

    function displayData () {
        if (!viewNationalCodes) return('');
        var ret = viewNationalCodes.map((nationalCode) => {
            return (diplayRow(nationalCode));
        })
        if (selectedNationalCode && selectedNationalCode.id==="0") {
            ret.unshift(diplayRow(selectedNationalCode)); 
        }
        return (ret);
    }

    function getColumns () {
        var columns = ["label.natcode"];
        if (!(searchOptions?.productType!=="")) columns = [...columns,"label.productType"];
        if (!(searchOptions?.sparklingType!=="")) columns = [...columns,"label.sparklingType"];
        if (!(searchOptions?.packageType!=="")) columns = [...columns,"label.packageType"];
        if (searchOptions?.abvYesNo==="true") columns = [...columns,"label.alcoholPerc"];
        if (searchOptions?.breweryYesNo==="true") columns = [...columns,"label.annualProductionHL"];
        return columns;
    }

    return (
        <div className="ShowNationalCodes">
            <div className='row'>
                <FormLineParagHeader col='3' label={"title.nationalCodes"}/>
            </div>
            <div className='row'>
                <FormLineSelect col="2" list={resources.generalProductTypes} name="productType" label="label.productType" value={searchOptions.productType} onChange={changeSearchHandler}/>
                <FormLineSelect col="2" list={resources.sparklingTypes} name="sparklingType" label="label.sparklingType" value={searchOptions.sparklingType} onChange={changeSearchHandler}/>
                <FormLineSelect col="2" list={resources.packageTypes} name="packageType" label="label.packageType" value={searchOptions.packageType} onChange={changeSearchHandler}/>
                <FormLineSelect col="2" list={resources.yesno} name="abvYesNo" label="label.abvYesNo" value={searchOptions.abvYesNo} onChange={changeSearchHandler}/>
                <FormLineSelect col="2" list={resources.yesno} name="breweryYesNo" label="label.breweryYesNo" value={searchOptions.breweryYesNo} onChange={changeSearchHandler}/>
            </div>
            <div className='row'>
                <div className='col-md-2'>
                    <FormButton onClick={addHandler} label='button.add'/>
                </div>
                <div className='col-md-2'>
                    <FormButton onClick={clearHandler} label='button.reset'/>
                </div>
            </div>
            <TableLayout columns={getColumns()} displayData={displayData}/>
        </div>   
    )
}