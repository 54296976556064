import React from 'react';
//import { useTranslation } from "react-i18next";
import { validNumber,validDecNumber,validOnlyLetters } from '../../Regex';
import PropTypes from 'prop-types';

TableRowCellInput.propTypes = {
    id: PropTypes.any, // Replace 'any' with the specific type you expect
    ref: PropTypes.any, // Replace 'any' with the specific type you expect
    type: PropTypes.any, // Replace 'any' with the specific type you expect
    name: PropTypes.any, // Replace 'any' with the specific type you expect
    value: PropTypes.any, // Replace 'any' with the specific type you expect
    onClick: PropTypes.any, // Replace 'any' with the specific type you expect
    readOnly: PropTypes.any, // Replace 'any' with the specific type you expect
    onChange: PropTypes.any, // Replace 'any' with the specific type you expect
    maxLen: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function TableRowCellInput (props) {
//    const {t} = useTranslation();
    const {id,ref,type,name,value,readOnly,onChange,maxLen,onClick} = props;
    //const text = value ? value : t(label);

    function changeHandler (e) {
        if (type==='number' && !validNumber.test(e.target.value)) return;
        if (type==='decNumber' && !validDecNumber.test(e.target.value)) return;
        if (type==='onlyLetters' && !validOnlyLetters.test(e.target.value)) return;
        onChange({id: id,name: name, value: e.target.value});    
    }

    function getType () {
        if (type==='number' || type==='decNumber' || type==='onlyLetters' || type==='phoneNumber') return 'text'
        return type;
    }

    if (readOnly && onClick) return (
        <td>
            <div onClick={onClick}>
                <input ref={ref} id={id} className="form-control border-0" type={getType()}  readOnly={true} name="value" value={value} onChange={changeHandler}/>
            </div>
        </td>
    )

    return (
             <td>
                <div>
                    <input ref={ref} id={id} className="form-control border-0" type={getType()}  readOnly={readOnly} name="value" value={value} onChange={changeHandler} maxLength={maxLen}/>
                </div>
             </td>
    );
}