import {useCallBackend} from '../General/CallBackend';
import { v4 as uuidv4 } from 'uuid';
import {log} from '../../Log';
import productTest from '../../resources/test/productTest.json'

export function CallRegisterProduct (product,login,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';
    log.debug("send JSON CallRegisterProduct");

    const body = {req : {...login,action: 2},...product}

    log.debug(body);
    log.debug("post");

    if (testMode) {
        log.debug("test")
    } else {
        
        CallBackend ("product", body, response => {
            responseFunc(response);
        });
    }
}

export function CallSaveBottleType (bottleType,login,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';
    log.debug("send JSON CallSaveBottleType");

    if (testMode) {
        const id = bottleType.id && bottleType.id !=='' ? bottleType.id : uuidv4();
        const ret = {...bottleType,id:id}

        log.debug(ret);

        //responseFunc (ret);
        responseFunc ({err : "error.server_return_error" });
    } else {
        const body = {req : {...login,action: 5},...bottleType};

        log.debug(body);
        log.debug("post");

        CallBackend ("product",body, response => {
            responseFunc(response);
        });
    }
}

export function CallGetAllProducerProducts (producerId,login,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';
    log.debug("send JSON CallGetAllProducts");

    if (testMode) {
        responseFunc ({products : productTest.products});
        //responseFunc ({err : "error.server_return_error" });
    }
    else {
        const body = { 
            req : {...login,action: 4},
            id : producerId
        }

        CallBackend ("product",body, response => {
            responseFunc(response);
        });
    }
}
