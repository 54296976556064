import {useCallBackend} from '../General/CallBackend';
import {log} from '../../Log';
import producerTest from '../../resources/test/producerTest.json';

export function CallGetProducerFromUser (login,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';
    log.debug("send JSON CallGetProducerFromUser");

    if (testMode) {
        //responseFunc ({err : "error.server_return_error"});
        responseFunc (producerTest.producer);
    }
    else {
        const body = { 
            req : {...login,action: 3}
        }

        log.debug(body);
        log.debug("post");

        CallBackend ("product",body, response => {
            responseFunc(response);
        });
    }
}

export function CallRegistrateProducer (input,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';

    log.debug("send JSON CallRegistrateProducer");

    if (testMode) {
        log.debug("Test");
    } else {

        const body = { 
        req : {action: 1},
        brandName: input.companyGeneralData.companyCommercialName,
        exciseNumber: input.companyGeneralData.exciseNumber,
        country: input.mainAddress.country,
        productTypes: input.generalProducerData.productTypes,
        company: {
            name: input.companyGeneralData.companyName,
            VATNumber: input.companyGeneralData.VATNumber,
            companyId: input.companyGeneralData.companyId,
            mainAddress: input.mainAddress
        },
        mainContact: {
            username: input.contactPerson.eMail,
            password: input.newPasswords.newPassword,
            person: input.contactPerson
        }
        }

        log.debug(body);
        log.debug("post");

        CallBackend ("product",body, response => {
            responseFunc(response);
        });
    }   
}

export function CallgetAllProducers (login,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';
    log.debug("send JSON getAllProducers");

    if (testMode) {
        //responseFunc ({err : "error.server_return_error"});
        responseFunc (producerTest.producers);
    }
    else {
        const body = { 
            req : {...login,action: 6}
        }

        log.debug(body);
        log.debug("post");

        CallBackend ("product",body, response => {
            responseFunc(response);
        });
    }
}

export function CallSaveProducer (login,producer,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';

    log.debug("send JSON CallSaveProducer");

    if (testMode) {
        log.debug("Test");
    } else {
        const body = { 
            req : {...login,action: 7},
            ...producer
        }

        log.debug(body);
        log.debug("post");

        CallBackend ("product",body, response => {
            responseFunc(response);
        });
    } 
}

