import axios from 'axios';
import { log } from '../../Log';
import { useDomain } from '../GeneralFunc';

const useAxiosInstance = () => {
    const domain = useDomain();

    // Set up default base URL and configurations
    return axios.create({
        baseURL: process.env.REACT_APP_BASEURL + domain + '/api/v1/',
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

// Utility function for error handling
const handleError = (error, responseFunc) => {
    log.debug('handleError in CallBackend(Conf)');
    log.debug(error);
    responseFunc({ err: 'error.server_return_error' });
};

export const useCallBackend = () => {
    const axiosInstance = useAxiosInstance();

    const CallBackend = (service, body, responseFunc) => {
        log.debug('send JSON CallBackend');
        log.debug(body);

        axiosInstance.post(`${service}/`, body)
            .then(response => {
                log.debug('response');
                log.debug(response);

                const { data } = response;
                if (data.ret && data.ret.error) {
                    log.debug('error 1');
                    const errMessage = data.ret.errorMessage + " (" + data.ret.errorCode + ")";
                    responseFunc({ err: errMessage, errCode : data.ret.errorCode});
                } else if (data.name && data.name === 'Error') {
                    log.debug('error 3');
                    responseFunc({ err: 'error.server_return_error' });
                } else {
                    responseFunc(data);
                }
            })
            .catch(error => handleError(error, responseFunc));
    };

    const CallBackendConf = (service, body, config, responseFunc) => {
        log.debug('send JSON CallBackendConf');
        log.debug(body);

        axiosInstance.post(`${service}/`, body, config)
            .then(response => {
                log.debug('response');
                log.debug(response);

                const { data } = response;
                if (data.ret && data.ret.error) {
                    log.debug('error 1');
                    log.debug(data.ret.errorMessage + " " + data.ret.errorCode);
                    const errMessage = data.ret.errorMessage + " (" + data.ret.errorCode + ")";
                    responseFunc({ err: errMessage });
                } else if (data.name && data.name === 'Error') {
                    log.debug('error 3');
                    responseFunc({ err: 'error.server_return_error' });
                } else {
                    responseFunc(data);
                }
            })
            .catch(error => handleError(error, responseFunc));
    };

    return { CallBackend, CallBackendConf };
};

/*
// Set up default base URL and configurations
const axiosInstance = axios.create({
    //baseURL: 'http://localhost/api/v1/', // ACC
    //baseURL: 'http://142.93.143.57/api/v1/',  //PRD
    baseURL: process.env.REACT_APP_BASEURL + useDomain() + '/api/v1/',
    headers: {
        'Content-Type': 'application/json'
    }
});

// Utility function for error handling
const handleError = (error, responseFunc) => {
    log.debug('handleError in CallBackend(Conf)');
    log.debug(error);
    responseFunc({ err: 'error.server_return_error' });
};

export default function CallBackend(service, body, responseFunc) {
    log.debug('send JSON CallBackend');
    log.debug(body);
    log.debug('post');

    axiosInstance.post(`${service}/`, body)
        .then(response => {
            log.debug('response');
            log.debug(response);
            log.debug(response.data);

            const { data } = response;
            if (data.ret && data.ret.error) {
                log.debug('error 1');
                const errMessage = data.ret.errorMessage + " (" + data.ret.errorCode + ")";
                responseFunc({ err: errMessage });
            } else if (data.name && data.name === 'Error') {
                log.debug('error 3');
                responseFunc({ err: 'error.server_return_error' });
            } else {
                responseFunc(data);
            }
        })
        .catch(error => handleError(error, responseFunc));
}

export function CallBackendConf(service, body, config, responseFunc) {
    log.debug('send JSON CallBackendConf');
    log.debug(body);
    log.debug('post');

    axiosInstance.post(`${service}/`, body, config)
        .then(response => {
            log.debug('response');
            log.debug(response);
            log.debug(response.data);

            const { data } = response;
            if (data.ret && data.ret.error) {
                log.debug('error 1');
                log.debug(data.ret.errorMessage + " " + data.ret.errorCode);
                const errMessage = data.ret.errorMessage + " (" + data.ret.errorCode + ")";
                responseFunc({ err: errMessage });
            } else if (data.name && data.name === 'Error') {
                log.debug('error 3');
                responseFunc({ err: 'error.server_return_error' });
            } else {
                responseFunc(data);
            }
        })
        .catch(error => handleError(error, responseFunc));
}
*/
/*
import axios from 'axios';
import {log} from '../../Log';

export default function CallBackend (service,body,responseFunc) {
    log.debug("send JSON CallBackend");

    log.debug(body);
    log.debug("post");

    axios.post("api/v1/" + service + "/", body)
    //axios.post("http://localhost:5000/api/v1/" + service + "/", body)
        .then(response => {
            log.debug('response');
            log.debug(response);
            log.debug(response.data);
            if (response.data.ret && response.data.ret.error) {
                log.debug('error 1');
                log.debug(response.data.ret.errorMessage);
                responseFunc ({err : response.data.ret.errorMessage});
            } else if (response.data.name && response.data.name==="Error") {
                log.debug('error 3');
                responseFunc ({err : "error.server_return_error"});
            }
            else {
                responseFunc(response.data);
            }
        })
        .catch(error => {
            log.debug('error 2');
            log.debug(error);
            responseFunc ({err : "error.server_return_error"});
        })
}

export function CallBackendConf (service,body,config,responseFunc) {
    log.debug("send JSON CallBackend");

    log.debug(body);
    log.debug("post");

    axios.post("api/v1/" + service + "/", body,config)
        .then(response => {
            log.debug('response');
            log.debug(response);
            log.debug(response.data);
            if (response.data.ret && response.data.ret.error) {
                log.debug('error 1');
                log.debug(response.data.ret.errorMessage);
                responseFunc ({err : response.data.ret.errorMessage});
            } else if (response.data.name && response.data.name==="Error") {
                log.debug('error 3');
                responseFunc ({err : "error.server_return_error"});
            }
            else {
                responseFunc(response.data);
            }
        })
        .catch(error => {
            log.debug('error 2');
            log.debug(error);
            responseFunc ({err : "error.server_return_error"});
        })
}*/