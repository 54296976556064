import React, {useContext,useEffect,useState} from 'react'
import { AppContext } from '../Drinkereez';
import {log} from '../../Log';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
import TableRowCell from '../layout/tables/TableRowCell';
import TableRowCellButton from '../layout/tables/TableRowCellButton';
import { CallGetAllUsers,CallActivateUserAdmin } from './CallUser';
import { GetLabelFromList } from '../GeneralFunc';
import resources from '../../resources/lists/dropdownlist.json';
import RegisterUser from './RegisterUser';
import FormButton from '../layout/forms/FormButton';
import TableLayout from '../layout/tables/TableLayout';

export default function ShowUsers() {
    const {login} = useContext(AppContext);
    const columns = ["label.name","label.eMail","label.username","label.type",""];
    const [users,setUsers] = useState ();
    const [showUser,setShowUser] = useState();
    const [newRecord,setNewRecord] = useState(false);
    const [selectedUser,setSelectedUser] = useState();

    useEffect(() => {
        getAllUsers();
      }, [login]);

    function getAllUsers() {
        CallGetAllUsers(login,response => {
            if (response.err || response.ret?.err) {
                log.debug("error");
                return;
            }
            log.debug(response);
            setUsers(response.users);
        });
    }  

    function activateUser (id) {
        CallActivateUserAdmin (login,id, response => {
            if (response.err || response.ret?.err) {
                log.debug("error");
                return;
            }
            log.debug(response);
            updateUserActivation(id);
        });
    }

    const updateUserActivation = (id) => {
        const updatedUsers = users.map(user => {
          if (user.id === id) {
            return { ...user, notActivated: 0 };
          }
          return user;
        });
    
        setUsers(updatedUsers);
    };

    function onClickTable (e) {
        setSelectedUser(users.find(({id}) => id === e.currentTarget.id));
        setShowUser(true);
        setNewRecord(false);
    }

    function displayData () {
        if (!users) return null;
        const ret = users.map((user) => {
            const name = user.person?.firstName + " " + user.person?.lastName;
            const activated = (user.notActivated === 1 ? "N": "")
            return (
                <tr key={user.id}>
                    <TableRowCell first="true" value={name} onClick={onClickTable} id={user.id}/>
                    <TableRowCell value={user.person?.eMail}/>
                    <TableRowCell value={user.username}/>
                    <TableRowCell label={GetLabelFromList(resources.userTypes,user.userType)}/>
                    {
                        user.notActivated ? 
                        <TableRowCellButton label="button.activate" onClick={() => activateUser(user.id)}/>
                        : <TableRowCell value={activated}/>
                    }
                </tr>
            )
        }) 
        return (ret);
    }

    function addUserHandler () {
        setShowUser(true);
        setNewRecord(true);
        setSelectedUser({});
    }

    function stopShowUser () {
        setShowUser(false);
    }

    function userSaved () {
        getAllUsers();
        setShowUser(false);
    }

    return (
        <div className="ShowUsers">
            <div className='row'>
                <FormLineParagHeader col='3' label={"title.users"}/>
                <div className='col-md-2'>
                    <FormButton onClick={addUserHandler} label='button.add'/>
                </div>
            </div>
            <TableLayout columns={columns} displayData={displayData}/>
            {
                showUser && <RegisterUser stopShowUser={stopShowUser} user={selectedUser} setUser={setSelectedUser} newRecord={newRecord} userSaved={userSaved}/>
            }
        </div>   
    )
}
