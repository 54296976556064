import React, {useContext} from 'react'
import { useTranslation } from "react-i18next";
import { AppContext } from '../Drinkereez';
import PropTypes from 'prop-types';

ReceiverB2BNavBar.propTypes = {
    navOptionClicked: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function ReceiverB2BNavBar(props) {
    const {navOptionClicked} = props;
    const {t} = useTranslation();
    const {user} = useContext(AppContext);

    function optionClicked (e) {
        navOptionClicked(e.target.id);
    }

    return (
        <ul className="nav nav-tabs nav-justified">
            <li className="nav-item">
                <a id="excises" className="nav-link link-dark" href="#nogo" onClick={optionClicked}>{t('tab.navbar_excises')}</a>
            </li>
            <li className="nav-item">
                <a id="tools" className="nav-link link-dark dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false" href="#nogo">{t('tab.navbar_tools')}</a>
                <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="#nogo" id="calculator" onClick={optionClicked}>{t('tab.navbar_calculator')}</a></li>
                    <li><a className="dropdown-item" href="#nogo" id="userData" onClick={optionClicked}>{user.person.firstName} {user.person.lastName}</a></li>
                </ul>
            </li>
        </ul>
    )
}