import React from 'react';
import { useTranslation } from "react-i18next";
import { GetLabelFromList } from '../../GeneralFunc';
import PropTypes from 'prop-types';

TableRowCellSelect.propTypes = {
    id: PropTypes.any, // Replace 'any' with the specific type you expect
    list: PropTypes.any, // Replace 'any' with the specific type you expect
    freeList: PropTypes.any, // Replace 'any' with the specific type you expect
    name: PropTypes.any, // Replace 'any' with the specific type you expect
    value: PropTypes.any, // Replace 'any' with the specific type you expect
    label: PropTypes.any, // Replace 'any' with the specific type you expect
    readOnly: PropTypes.any, // Replace 'any' with the specific type you expect
    onChange: PropTypes.any, // Replace 'any' with the specific type you expect
};
export default function TableRowCellSelect (props) {
    const {t} = useTranslation();
    const {id,list,name,value,readOnly,onChange,freeList,label} = props;
    //const text = value ? value : t(label);

    function changeHandler (e) {
        onChange({id: id,name: name, value: e.target.value});    
    }

    function dropdownElement (element) {
        if (!freeList) return (<option key={element.value} value={element.value}>{t(element.label)}</option>);
        return (<option key={element.value} value={element.value}>{element.label}</option>);
       }
    
    if (!value && readOnly) {
        return (<><input className="form-control border-0" readOnly={true}/></>);
    }

    return (
             <td>
            { readOnly ? <input className="form-control border-0" readOnly={readOnly} name="value" value={t(GetLabelFromList(list,value))}/>:
            <select className="form-select border-0" name="value" value={value} onChange={changeHandler}>
                {
                    (!value || value==="") && <option defaultValue value=''>{t("label.selectA")} {t(label)} ...</option>
                }
                {
                    list && list.map((element) => {
                        return dropdownElement(element);
                    })
                }
            </select>}
             </td>
             );
}