import React, {useState} from 'react';
//import { AppContext } from '../Drinkereez';
import FormLineInput from '../layout/forms/FormLineInput';
import FormButton from '../layout/forms/FormButton';
import Trader from '../General/Trader'
import {log} from '../../Log';
import TableRowCell from '../layout/tables/TableRowCell';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
import TableLayout from '../layout/tables/TableLayout';
import ShowIE801Product from './ShowIE801Product';
import PropTypes from 'prop-types';

ShowIE801.propTypes = {
    exciseDoc: PropTypes.any, // Replace 'any' with the specific type you expect
    cancelViewDoc: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function ShowIE801(props) {
    const {exciseDoc, cancelViewDoc} = props; 
//    const {login} = useContext(AppContext);
    const columns=["label.reference","label.commercialDescription","label.cnCode","label.exciseProductCode"];
    const [selectedProd,setSelectedProd] = useState();

    function cancelHandler () {
        cancelViewDoc();
    }

    function viewProduct (e) {
        setSelectedProd(exciseDoc.products.find(({id}) => id === e.currentTarget.id));
    }

    function cancelViewProd () {
        setSelectedProd();
    }

    function displayData () {
        if (!exciseDoc.products) return ('');

        log.debug(exciseDoc.products);

        const ret = exciseDoc.products.map((product) => {
            return (
                <tr key={product.id}>
                    <TableRowCell value={product.reference} onClick={viewProduct} id={product.id}/>
                    <TableRowCell value={product.commercialDescription}/>
                    <TableRowCell value={product.cnCode}/>
                    <TableRowCell value={product.exciseProductCode}/>
                </tr>
            )
        }) 
        return (ret);
    }

    return (
        <div className='showIE801'>
            <div className='row'>
                <FormLineInput col="3" type="text" readOnly={true} label="label.messageRecipient" value={exciseDoc.messageRecipient}/>
                <FormLineInput col="5" type="text" readOnly={true} label="label.messageIdentifier" value={exciseDoc.messageIdentifier}/>
                <FormLineInput col="4" type="text" readOnly={true} label="label.messageSender" value={exciseDoc.messageSender}/>
            </div>
            <div className='row'>
                <FormLineInput col="4" type="text" readOnly={true} label="label.referenceCodeEAD" value={exciseDoc.referenceCodeEAD}/>
                <FormLineInput col="4" type="text" readOnly={true} label="label.referenceNumberDispatchOffice" value={exciseDoc.referenceNumberDispatchOffice}/>
            </div>
            <div className='row'>
                <FormLineInput col="4" type="text" readOnly={true} label="label.transportUnitCode" value={exciseDoc.transportUnitCode}/>
                <FormLineInput col="4" type="text" readOnly={true} label="label.transportUnitId" value={exciseDoc.transportUnitId}/>
                <FormLineInput col="4" type="text" readOnly={true} label="label.transportModeCode" value={exciseDoc.transportModeCode}/>
            </div>
            <Trader header="label.consignee" trader={exciseDoc.consigneeTrader}/>
            <Trader header="label.consignor" trader={exciseDoc.consignorTrader}/>

            <div className='row'>
                <FormLineParagHeader col='3' label={"title.products"}/>
            </div>
            <TableLayout columns={columns} displayData={displayData}/>

            {
                selectedProd && <ShowIE801Product product={selectedProd} cancelViewProd={cancelViewProd}/>
            }

            <FormButton onClick={cancelHandler} level='secondary' label='button.cancel'/>
        </div>
    )
}