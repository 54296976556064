export const log = {debug,info,warning,error};

const logLevel = process.env.REACT_APP_PRINTLOG;

function debug (text) {
    if (logLevel==="DEBUG") {
        console.log(text);
    }
}

function info (text) {
    if (logLevel==="DEBUG" || logLevel==="INFO") {
        console.log(text);
    }
}

function warning (text) {
    if (logLevel==="DEBUG" || logLevel==="INFO" || logLevel==="WARNING") {
        console.log(text);
    }
}

function error (text) {
    if (logLevel==="DEBUG" || logLevel==="INFO"  || logLevel==="WARNING" || logLevel==="ERROR") {
        console.log(text);
    }
}
