import React from 'react';
//import { ExciseContext } from './ExcisePage';
//import { AppContext } from '../Drinkereez';
import FormLineInput from '../layout/forms/FormLineInput';
import FormButton from '../layout/forms/FormButton';
//import {log} from '../../Log';

export default function ShowIE801Product(prod) {
    const {product, cancelViewProd} = prod; 
//    const {login} = useContext(AppContext);

/*    function cancelHandler (e) {
        cancelViewProd();
    }*/

    return (
        <div className='showIE801Product'>
            <div className='row'>
                <FormLineInput col="4" type="text" readOnly={true} label="label.reference" value={product.reference}/>
                <FormLineInput col="4" type="text" readOnly={true} label="label.cnCode" value={product.cnCode}/>
                <FormLineInput col="4" type="text" readOnly={true} label="label.exciseProductCode" value={product.exciseProductCode}/>
            </div>
            <div className='row'>
                <FormLineInput col="4" type="text" readOnly={true} label="label.commercialDescription" value={product.commercialDescription}/>
                <FormLineInput col="4" type="text" readOnly={true} label="label.alcoholPercentage" value={product.alcoholPercentage}/>
                <FormLineInput col="4" type="text" readOnly={true} label="label.language" value={product.language}/>
            </div>
            <div className='row'>
                <FormLineInput col="4" type="text" readOnly={true} label="label.kindOfPackagesCode" value={product.kindOfPackagesCode}/>
                <FormLineInput col="4" type="text" readOnly={true} label="label.numberOfPackages" value={product.numberOfPackages}/>
                <FormLineInput col="4" type="text" readOnly={true} label="label.fiscalMarkUsed" value={product.fiscalMarkUsed}/>
            </div>
            <div className='row'>
                <FormLineInput col="4" type="text" readOnly={true} label="label.quantity" value={product.quantity}/>
                <FormLineInput col="4" type="text" readOnly={true} label="label.netWeight" value={product.netWeight}/>
                <FormLineInput col="4" type="text" readOnly={true} label="label.grossWeight" value={product.grossWeight}/>
            </div>
            <FormButton onClick={cancelViewProd} level='secondary' label='button.cancel'/>
        </div>
    )
}