import React from 'react';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';

FormLineTextArea.propTypes = {
    name: PropTypes.string.isRequired, // The name of the textarea
    value: PropTypes.string,           // The current value of the textarea
    label: PropTypes.string.isRequired, // The label for the textarea
    rows: PropTypes.number,            // Number of rows in the textarea
    required: PropTypes.bool,          // Whether the textarea is required
    onChange: PropTypes.func           // Function to call on value change
};

FormLineTextArea.defaultProps = {
    required: false,
    readOnly: false,
    rows: 3
};

export default function FormLineTextArea ({ name, value, label, rows , required, readOnly, onChange }) {
    const { t } = useTranslation();

    function changeHandler(e) {
        if (!readOnly) {
            onChange({ name, value: e.target.value });    
        }
    }

    return (
        <div className="form-floating my-2">
            <textarea 
                className="form-control" 
                id={`textarea-${name}`} 
                name={name} 
                value={value || ''} 
                rows={rows} 
                onChange={changeHandler} 
                required={required}
                aria-label={t(label)} // Accessibility improvement
                style={{ minHeight: `calc(1.5em * ${rows})` }}
                readOnly={readOnly}
            />
            <label htmlFor={`textarea-${name}`}>{t(label)}</label>
        </div>
    );
}

/*
import React from 'react';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';

FormLineTextArea.propTypes = {
    name: PropTypes.any, // Replace 'any' with the specific type you expect
    value: PropTypes.any, // Replace 'any' with the specific type you expect
    type: PropTypes.any, // Replace 'any' with the specific type you expect
    label: PropTypes.any, // Replace 'any' with the specific type you expect
    required: PropTypes.any, // Replace 'any' with the specific type you expect
    rows: PropTypes.any, // Replace 'any' with the specific type you expect
    onChange: PropTypes.any, // Replace 'any' with the specific type you expect
};
export default function FormLineTextArea (props) {
    const {t} = useTranslation();
//    const {name,value,label,type,rows,required,readOnly,onChange} = props;
    const {name,value,label,type,rows,required,onChange} = props;
    const req = required && required.toUpperCase()==="TRUE";

    function changeHandler (e) {
        onChange({name: name,value: e.target.value});    
    }

/*    return (
        <div className="form-group row my-2">
            <label className="col-sm-3 col-form-label text-md-left text-dark">{getLabel(props.label)}</label>
            <div className="col-sm-9">
                {
                    props.readOnly ?
                    <div className="card card-body">
                        {props.value}
                    </div>
                    :
                    <textarea type={props.type} className="form-control" name="value" value={props.value} rows={props.rows} onChange={changeHandler} required={required}/>
                }
            </div>
        </div>
    );  *//*

    return (
        <div className="row">
            <div className="form-floating my-2">
                <textarea type={type} id="floatingTextarea" className="form-control" name="value" value={value} rows={rows} onChange={changeHandler} required={req}/>
                <label htmlFor="floatingTextarea">{t(label)}</label>
            </div>
        </div>
    );  
} */