import React, {useEffect, useContext, useState} from 'react';
import { AppContext } from '../Drinkereez';
import { CallGetAllProducerProducts } from './CallProduct';
import RegisterProduct from './RegisterProduct';
import ShowWineProducts from './Wine/ShowWineProducts';
import ShowSpiritProducts from './Spirit/ShowSpiritProducts';
import ShowBeerProducts from './Beer/ShowBeerProducts';
import {log} from '../../Log';

export const ProductContext = React.createContext();

export default function ShowProducts() {
    const {login,producer} = useContext(AppContext);
    const [products,setProducts] = useState ();
    const [wineProducts,setWineProducts] = useState ();
    const [spiritProducts,setSpiritProducts] = useState ();
    const [beerProducts,setBeerProducts] = useState ();
    const [selectedProduct,setSelectedProduct] = useState();
    const [productType,setProductType] = useState ();
    const [showListProducts,setShowListProducts] = useState (true);
    const [showRegiterProduct,setShowRegisterProduct] = useState (false);
    const [showEditProduct,setShowEditProduct] = useState (false);
    const [editMode,setEditMode] = useState (false);


    useEffect(() => {
        getAllProducts();
      }, [producer]);

    function viewRecord (prodId,prodType) {
        log.debug("showProducts - viewRecord");
        log.debug("view : " + prodId + " " + prodType);
        const prod = products.find(({id}) => id === prodId);
        setSelectedProduct(prod);
        setProductType(prodType);
        setShowListProducts(false);
        setShowRegisterProduct(false);
        setShowEditProduct(true);
        setEditMode(false);
    }

    function editRecord (e) {
        log.debug("showProducts - editRecord");
        log.debug("edit : " + e.currentTarget.id + " " + e.productType);
        const prod = products.find(({id}) => id === e.currentTarget.id);
        setSelectedProduct(prod);
        setProductType(e.productType);
        setShowListProducts(false);
        setShowRegisterProduct(false);
        setShowEditProduct(true);
        setEditMode(true);
    }

    function addRecord (type) {
        log.debug("showProducts - addRecord");
        log.debug("add record showProducts : " + type);
        setSelectedProduct({});
        setProductType(type);
        setShowListProducts(false);
        setShowRegisterProduct(true);
        setShowEditProduct(false);
        setEditMode(true);
    }

    function cancelEditRecord () {
        getAllProducts();
        setShowListProducts(true);
        setShowRegisterProduct(false);
        setShowEditProduct(false);
    }

    function getAllProducts () {
        CallGetAllProducerProducts(producer.id,login,response => {
            if (response.err || (response.ret && response.ret.err)) {
                log.debug("error")
            } else {
                const prods = response.products;
                let wineProds = [];
                let spiritProds = [];
                let beerProds = [];
                prods.map((product) => {
                    if (product.mainType==="PRODUCT_TYPE_GENERAL_WINE") {
                        wineProds.push(product);
                    }
                    else if (product.mainType==="PRODUCT_TYPE_GENERAL_SPIRIT") {
                        spiritProds.push(product);
                    }
                    else if (product.mainType==="PRODUCT_TYPE_GENERAL_BEER") {
                        beerProds.push(product);
                    }
                    return "";
                })
                log.debug("showProducts - Products");
                log.debug(prods);
                log.debug(wineProds);
                log.debug(spiritProds);
                log.debug(beerProds);
                setProducts(prods);
                setWineProducts(wineProds);
                setSpiritProducts(spiritProds);
                setBeerProducts(beerProds);
            }
        });
    }

    return (
        <ProductContext.Provider value={{product: selectedProduct, setProduct: setSelectedProduct, viewRecord, editRecord, addRecord, cancelEditRecord}}>
            <div className="ShowProducts">
                { 
                    showListProducts && <ShowWineProducts wineProducts={wineProducts}/>
                }
                { 
                    showListProducts && <ShowSpiritProducts spiritProducts={spiritProducts}/>
                }
                {    
                    showListProducts && <ShowBeerProducts beerProducts={beerProducts}/>
                }
                {
                    showRegiterProduct && <RegisterProduct editMode={editMode} registerMode={true} type={productType}/>
                }
                {
                    showEditProduct && <RegisterProduct editMode={editMode} registerMode={false} type={productType}/>
                }
            </div>
        </ProductContext.Provider>
    )
}
