import React from 'react';
import { useTranslation } from "react-i18next";
import settings from '../../../resources/generalSettings.json'
import PropTypes from 'prop-types';

FormButton.propTypes = {
    label: PropTypes.any, // Replace 'any' with the specific type you expect
    className: PropTypes.any, // Replace 'any' with the specific type you expect
    level: PropTypes.any, // Replace 'any' with the specific type you expect
    onClick: PropTypes.any, // Replace 'any' with the specific type you expect
    id: PropTypes.any, // Replace 'any' with the specific type you expect
    type: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function FormButton (props) {
    const {t} = useTranslation();
    const {label,className,level,onClick,id} = props;
    const type = props.type ? props.type : "button";

    function readLevel () {
        switch (level) {
            case 'primary' :
                return settings.button_primary;
            case 'secondary' :
                return settings.button_secondary;
            default :
                return settings.button_default;
        }
    }

    function createClassName () {
        const extraClassName = className ? className : 'm-2';
        return "btn " + readLevel() + " " + extraClassName;
    }

    return (
        <><button id={id} type={type} onClick={onClick} className={createClassName()}>{t(label)}</button></>
    )
}