import {useCallBackend} from '../General/CallBackend';
import {log} from '../../Log';
import userTest from '../../resources/test/userTest.json'

export function CallUpdateUser(userId,person,login,responseFunc) {
    const { CallBackend } = useCallBackend();
    const body = { 
        req : {...login,action: 2},
        id : userId,
        person: person
    }       

    log.info("send JSON");
    log.info({body:body});

    CallBackend ("user",body, response => {
        responseFunc(response);
    });
}

export function CallChangePassword(userId,newPassword,login,responseFunc) {
    const { CallBackend } = useCallBackend();
    const body = { 
        req : {...login,action: 1},
        id : userId,
        password: newPassword
    }       

    log.info("send JSON");
    log.info({body:body});

    CallBackend ("user",body,response => {
        responseFunc(response);
    });
}

export function CallCheckUserNamePassword (username,password,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';

    log.info("send JSON CallCheckUserNamePassword : " + testMode);

    if (testMode) {
        const user = (username==='admin') ? userTest.adminUser : 
            (username==='rec') ? userTest.receiverUser : 
                (username==='test1') ? userTest.clientUser1 :
        userTest.clientUser2 ;
      
        responseFunc (user);
        //responseFunc ({err : "error.server_return_error" });
    }
    else {
        const body = { 
            req : { 
                usr: username,
                pwd: password,
                action: 0
            }
        }

        CallBackend ("user",body, response => {
            responseFunc(response);
        });
    }
}

export function CallGetAllUsers (login,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';

    log.info("send JSON CallGetAllUsers : " + testMode);

    if (testMode) {
        const users = userTest.users ;
      
        responseFunc (users);
    }
    else {
        const body = { 
            req : {...login,action: 3}
        }

        CallBackend ("user",body, response => {
            responseFunc(response);
        });
    }
}

export function CallSaveUser (login,user,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';

    log.info("send JSON CallSaveUser : " + testMode);

    if (testMode) {
        const ret = {...user,id:'12345'} ;
      
        responseFunc (ret);
    }
    else {
        const body = { 
            req : {...login,action: 4},
            ...user
        } 

        CallBackend ("user",body, response => {
            responseFunc(response);
        });
    }
}

export function CallActivateUserAdmin (login,id,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';

    log.info("send JSON CallActivateUserAdmin : " + testMode);

    const body = { 
        req : {...login,id: id,action: 6},
    } 

    if (testMode) {
        const ret = {id: id} ;
        responseFunc (ret);
    }
    else {
        CallBackend ("user",body, response => {
            responseFunc(response);
        });
    }
}

export function CallActivateUser (userId,actCode,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';

    log.info("send JSON CallActivateUserAdmin : " + testMode);

    const body = { 
        req : {usr:userId, pwd: actCode,action: 5},
    } 

    if (testMode) {
        const ret = {id: userId} ;
        //responseFunc (ret);
        responseFunc ({err : "error.server_return_error" });
    }
    else {
        CallBackend ("user",body, response => {
            responseFunc(response);
        });
    }
}

/*export function CallAdminChangePassword (login,userId,password,responseFunc) {
    //TODO
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';

    log.info("send JSON CallAdminChangePassword : " + testMode);

    if (testMode) {
        responseFunc ({});
    }
    else {
        const body = { 
            req : {...login,action: 5},
            id: userId,password
        } 

        CallBackend ("user",body, response => {
            responseFunc(response);
        });
    }
}*/
