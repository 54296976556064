import React,{useState,useContext} from 'react';
import { AppContext } from '../Drinkereez';
import ShowProducers from './ShowProducers';
import ModifyProducer from './ModifyProducer';

export default function ProducerPage() {
    const {producer,setProducer} = useContext(AppContext);
    const [showProducerList, setShowProducerList] = useState(true);
    const [showProducer,setShowProducer] = useState(false);

    function producerSelected (prod) {
        setShowProducerList(false);
        setShowProducer(true);
        setProducer(prod);       
    }

    function stopShowProducer () {
        setShowProducerList(true);
        setShowProducer(false);
    }

    function producerSaved () {
        //getAllProducers();
        stopShowProducer();
    }

    return (
        <div className="ShowProducer">
            {
                showProducerList && <ShowProducers producerSelected={producerSelected}/>
            }
            {
                showProducer && <ModifyProducer producer={producer} setProducer={setProducer} stopShowProducer={stopShowProducer} producerSaved={producerSaved}/>
            }
        </div>
    )
}
