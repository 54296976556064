import React from 'react';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';

FormLineSuccessMessage.propTypes = {
    message: PropTypes.any, // Replace 'any' with the specific type you expect
    extraMessage: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function FormLineSuccessMessage (props) {
    const {t} = useTranslation();
    const {message,extraMessage} = props;
    const messageEmpty = message === "";
    let showDetails = false; // eslint-disable-line no-unused-vars
    const showDetailsLink = extraMessage && extraMessage != "";

    function detailsClicked() {
        showDetails = true;
    }

    function printError () {
        if (messageEmpty) return ("");
        else 
        return (
            <div className="alert alert-success" role="alert">
                <div>{t(message)} 
                    { 
                        showDetailsLink &&<a onClick={detailsClicked} data-bs-toggle="collapse" href="#collapseSuccess" className="link-success" aria-expanded="false" aria-controls="collapseError">details</a>
                    }
                </div>
                {
                    showDetailsLink && <div className="collapse" id="collapseSuccess">{extraMessage}</div>
                }
            </div>
        )
    }

    return (
        printError() 
    );  
}