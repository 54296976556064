import React from 'react';
import Calculator from '../Calculator/Calculator';
import UserData from '../User/UserData';
//import {log} from '../../Log';
import ExcisePage from '../Excise/ExcisePage';
import PropTypes from 'prop-types';

ReceiverB2BPortal.propTypes = {
    screenToShow: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function ReceiverB2BPortal(props) {
    const {screenToShow} = props;

    return (
        <div className="receiverPortal container">
            {
                screenToShow==='calculator' && <Calculator/>
            }  
            {
                screenToShow==='userData' && <UserData/>
            }
            {
                screenToShow==='excises' && <ExcisePage/>
            }
        </div>
    )

}