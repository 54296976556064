import React, {useState,useContext} from 'react'
import { AppContext } from '../Drinkereez';
import { ProductContext } from './ShowProducts';
import RegisterWineProduct from './Wine/RegisterWineProduct';
import RegisterWineBottleType from './Wine/RegisterWineBottleType';
import RegisterSpiritProduct from './Spirit/RegisterSpiritProduct';
import RegisterSpiritBottleType from './Spirit/RegisterSpiritBottleType';
import RegisterBeerProduct from './Beer/RegisterBeerProduct';
import RegisterBeerBottleType from './Beer/RegisterBeerBottleType';
import {CallRegisterProduct,CallSaveBottleType} from './CallProduct';
import FormLineErrorMessage from '../layout/forms/FormLineErrorMessage';
import FormLineSuccessMessage from '../layout/forms/FormLineSuccessMessage';
import ListBottleTypes from './ListBottleTypes';
import {log} from '../../Log';
import FormButton from '../layout/forms/FormButton';
import {CallSendProduct} from '../Shop/CallShop';
import FormLineFileInput from '../layout/forms/FormLineFileInput';
import PropTypes from 'prop-types';

RegisterProduct.propTypes = {
    registerMode: PropTypes.any, // Replace 'any' with the specific type you expect
    type: PropTypes.any, // Replace 'any' with the specific type you expect
    editMode: PropTypes.any // Replace 'any' with the specific type you expect
};

export const ProductExtraContext = React.createContext();

export default function RegisterProduct(props) {
    const {registerMode,type} = props;
    const isWineProduct = type==="WINE";
    const isSpiritProduct = type==="SPIRIT";
    const isBeerProduct = type==="BEER";
    const {login,user,producer} = useContext(AppContext);
    const {product, setProduct,cancelEditRecord} = useContext(ProductContext);
    const [bottleTypes,setBottleTypes] = useState(product && product.bottleTypes ? product.bottleTypes : []);
    const [editMode,setEditMode] = useState (props.editMode);
    const [selectedBottleType,setSelectedBottleType] = useState({});
    const [error, setError] = useState({errorMessage: '',errorJSON: {}});
    const [showBT,setShowBT] = useState(false);
    const [editBT,setEditBT] = useState(false);
    const [success, setSuccess] = useState({message: '',extraMessage: ''}); // eslint-disable-line no-unused-vars
    

    function saveHandler () {
        CallRegisterProduct(product,login,response => {
            if (response.err) {
                setError({errorMessage : response.err, errorJSON: {action: 'save CallRegisterProduct', product}});
            } else {
                setError({errorMessage : '', errorJSON: {}});
                setEditMode(false);
            }
        });
    }

    function cancelHandler () {
        if (registerMode || !editMode) {
            cancelEditRecord();
        }
        setEditMode(false);
    }

    function editHandler () {
        setEditMode(true);
    }

    //function resetHandler () {
    //}

    function submitHandler () {
        const bottleTypes = [];
        bottleTypes.push(selectedBottleType);
        const newProduct = {...product,producerId : producer.id,bottleTypes : bottleTypes};
        setProduct(newProduct);
        log.debug("voor CallRegisterProduct");
        log.debug(newProduct);
        CallRegisterProduct(newProduct,login,response => {
            if (response.err) {
                setError({errorMessage: response.err, errorJSON: {action: 'submit CallRegisterProduct', product : newProduct}});
            } else {
                setError({errorMessage: '', errorJSON: {}});
                cancelEditRecord();
            }
        });        
    }

    function returnError (message,errorJSON) {
        setError({errorMessage: message, errorJSON: errorJSON});
    } 

    function addBottleType () {
        setSelectedBottleType({});
        setShowBT(true);
        setEditBT(true);
    }

    function viewBottleType (e) {
        log.debug("view bottle : " + e.currentTarget.id);
        const bottleType = bottleTypes.find(({id}) => id === e.currentTarget.id);
        setSelectedBottleType(bottleType);
        setShowBT(true);
        setEditBT(false);
    }

    function editBottleType (e) {
        log.debug("edit bottle : " + e.currentTarget.id);
        const bottleType = bottleTypes.find(({id}) => id === e.currentTarget.id);
        setSelectedBottleType(bottleType);
        setShowBT(true);
        setEditBT(true);
    }

    function cancelHandlerBT () {
        setShowBT(false);
        setEditBT(false);
    }

    function editHandlerBT () {
        setShowBT(true);
        setEditBT(true);
    }

    function updateBottleTypes (bottleType) {
        let bottleTs = [];
        let inserted = false;
        bottleTypes.map(bt => {
            if (bottleType.id===bt.id) {
                bottleTs.push(bottleType);
                inserted = true;
            } else {
                bottleTs.push(bt);
            }
            return "";
        })

        if (!inserted) {
            bottleTs.push(bottleType);
        }

        log.debug(bottleTypes);
        setBottleTypes(bottleTs);
        setSelectedBottleType({});
        setShowBT(false);
        setEditBT(false);
    }

    function saveHandlerBT () {
        log.debug("saveHandlerBT");
        const bottleType = {...selectedBottleType, productId: product.id}
        
        CallSaveBottleType(bottleType,login,response => {
            if (response.err) {
                const errorJSON = {action: 'save CallSaveBottleType', bottleType : bottleType};
                setError({errorMessage: response.err, errorJSON: errorJSON});
            } else {
                setError({errorMessage: "", errorJSON: {}});
                updateBottleTypes(response);
            }
        });        
    }

    function shopHandler () {
        CallSendProduct(selectedBottleType.id,login,response => {
            if (response.err || response.ret?.err) {
                log.debug("error");
                setError({errorMessage: response.err + ' ' + response.ret?.err, errorJSON: {login,selectedBottleType}});
                return;
            } else {
                log.debug(response);
                //setSuccess({message: 'Product send to PrestaShop'});
                return;
            }
        });
    }

    if (registerMode) {
        return (
            <ProductExtraContext.Provider value={{bottleType: selectedBottleType, setBottleType: setSelectedBottleType}}>
            <div className="RegisterProduct">
                {
                    isWineProduct && <><RegisterWineProduct returnError={returnError} editMode={editMode}/>
                                       <RegisterWineBottleType returnError={returnError} editMode={editMode}/></>
                }
                { 
                    isSpiritProduct && <><RegisterSpiritProduct returnError={returnError} editMode={editMode}/> 
                                         <RegisterSpiritBottleType returnError={returnError} editMode={editMode}/></>
                }
                {
                    isBeerProduct && <><RegisterBeerProduct returnError={returnError} editMode={editMode}/>
                                       <RegisterBeerBottleType returnError={returnError} editMode={editMode}/></>
                }
                <FormLineErrorMessage message={error.errorMessage} errorJSON={error.errorJSON}/>
                <FormButton onClick={submitHandler} label='button.submit'/>
                <FormButton onClick={cancelHandler} level='secondary' label='button.cancel'/>
            </div>
            </ProductExtraContext.Provider>
        );
    } else {
        return (
            <ProductExtraContext.Provider value={{bottleType: selectedBottleType, setBottleType: setSelectedBottleType, viewBottleType, editBottleType, addBottleType}}>
            <div className="RegisterProduct">
                {
                    isWineProduct && <RegisterWineProduct editMode={editMode}/>
                }
                {
                    isSpiritProduct && <RegisterSpiritProduct editMode={editMode}/>
                }
                {
                   isBeerProduct && <RegisterBeerProduct editMode={editMode}/>
                }
                <FormLineErrorMessage message={error.errorMessage} errorJSON={error.errorJSON}/>
                <FormLineSuccessMessage message={success.message} extraMessage={success.extraMessage}/>  
                { 
                    editMode && <FormButton onClick={saveHandler} label='button.save'/>
                }
                { 
                    !editMode && <FormButton onClick={editHandler} label='button.edit'/>
                }
                <FormButton onClick={cancelHandler} level='secondary' label='button.cancel'/>
                <ListBottleTypes bottleTypes={bottleTypes} hideAddButton={showBT || editBT}/>
                { 
                    (showBT || editBT) && isWineProduct && <RegisterWineBottleType editMode={editBT}/>
                }
                { 
                    (showBT || editBT) && isSpiritProduct && <RegisterSpiritBottleType editMode={editBT}/>
                }
                { 
                    (showBT || editBT) && isBeerProduct && <RegisterBeerBottleType editMode={editBT}/>
                }
                {
                    (showBT && !editBT) && <FormLineFileInput label='label.uploadPhoto'/>
                }
                {
                    (showBT && !editBT) && <FormButton onClick={editHandlerBT} label='button.edit'/>
                }
                {
                    editBT && <FormButton onClick={saveHandlerBT} label='button.save'/>
                }
                {
                    (showBT || editBT) && <FormButton onClick={cancelHandlerBT} level='secondary' label='button.cancel'/>
                }
                {
                    (showBT || editBT) && user?.userType==='USER_TYPE_ADMIN' && (!selectedBottleType.psId || selectedBottleType.psId===0) && <FormButton onClick={shopHandler} label='button.sendPrestashop'/>
                }
                {
                    (showBT || editBT) && user?.userType==='USER_TYPE_ADMIN' && (selectedBottleType.psId>0) && <FormButton onClick={shopHandler} label='button.updatePrestashop'/>
                }
            </div>
            </ProductExtraContext.Provider>
        );
    }

}
