import React from 'react';
import { useTranslation } from "react-i18next";
//import {log} from '../../../Log';
import { orderByField,getListByPercentage } from '../../GeneralFunc';
import PropTypes from 'prop-types';

FormLineCheckBoxGroup.propTypes = {
    label: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
    })),
    checkedList: PropTypes.arrayOf(PropTypes.string),
    setCheckedList: PropTypes.func,
    readOnly: PropTypes.bool
};

export default function FormLineCheckBoxGroup (props) {
    const {t} = useTranslation();
//    const {label,list,checkedList,setCheckedList,onChange,readOnly} = props;
    const {label,list,checkedList,setCheckedList,readOnly} = props;
    const orderList = orderByField(list,"label");
    const list1 = getListByPercentage(orderList,20,20);
    const list2 = getListByPercentage(orderList,40,20);
    const list3 = getListByPercentage(orderList,60,20);
    const list4 = getListByPercentage(orderList,80,20);
    const list5 = getListByPercentage(orderList,100,20);

    function changeHandler (e) {
        if (e.target.checked) {
            if (checkedList)
                setCheckedList([...checkedList,e.target.id]);            
            else 
                setCheckedList([e.target.id]);
        } else { 
            setCheckedList(checkedList.filter(id => id!==e.target.id));
        }
    }

    function checkId (id) {
        return (checkedList && checkedList.includes(id));
    }

    function renderCheckboxList(list) {
        return list.map((element) => (
            <div key={element.value}>
                <input className="form-check-input" type="checkbox" id={element.value} onChange={changeHandler} disabled={readOnly} checked={checkId(element.value)}/>
                <label className="form-check-label text-dark px-2">{t(element.label)}</label>
            </div>
        ));
    }
    
    return (
        <div className="form-group row my-2">
            <label className="col-sm-2 col-form-label text-md-left text-dark">{t(label)}</label>
            <div className="col-sm-2">
                {renderCheckboxList(list1)}
            </div>
            <div className="col-sm-2">
                {renderCheckboxList(list2)}
            </div>
            <div className="col-sm-2">
                {renderCheckboxList(list3)}
            </div>
            <div className="col-sm-2">
                {renderCheckboxList(list4)}
            </div>
            <div className="col-sm-2">
                {renderCheckboxList(list5)}
            </div>
        </div>
    );  

    /*
    return (
        <div className="form-group row my-2">
            <label className="col-sm-2 col-form-label text-md-left text-dark">{t(label)}</label>
            <div className="col-sm-2">
                {
                    list1.map((element) => {
                        return(
                            <div>
                                <input className="form-check-input" type="checkbox" id={element.value} onChange={changeHandler} disabled={readOnly} checked={checkId(element.value)}/>
                                <label className="form-check-label text-dark px-2">{t(element.label)}</label>
                            </div>
                        )
                    })
                }
            </div>
            <div className="col-sm-2">
                {
                    list2.map((element) => {
                        return(
                            <div>
                                <input className="form-check-input" type="checkbox" id={element.value} onChange={changeHandler} disabled={readOnly} checked={checkId(element.value)}/>
                                <label className="form-check-label text-dark px-2">{t(element.label)}</label>
                            </div>
                        )
                    })
                }
            </div>
            <div className="col-sm-2">
                {
                    list3.map((element) => {
                        return(
                            <div>
                                <input className="form-check-input" type="checkbox" id={element.value} onChange={changeHandler} disabled={readOnly} checked={checkId(element.value)}/>
                                <label className="form-check-label text-dark px-2">{t(element.label)}</label>
                            </div>
                        )
                    })
                }
            </div>
            <div className="col-sm-2">
                {
                    list4.map((element) => {
                        return(
                            <div>
                                <input className="form-check-input" type="checkbox" id={element.value} onChange={changeHandler} disabled={readOnly} checked={checkId(element.value)}/>
                                <label className="form-check-label text-dark px-2">{t(element.label)}</label>
                            </div>
                        )
                    })
                }
            </div>
            <div className="col-sm-2">
                {
                    list5.map((element) => {
                        return(
                            <div>
                                <input className="form-check-input" type="checkbox" id={element.value} onChange={changeHandler} disabled={readOnly} checked={checkId(element.value)}/>
                                <label className="form-check-label text-dark px-2">{t(element.label)}</label>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );  */
}