import React from 'react';
import {log} from '../../../Log';
import { useTranslation } from "react-i18next";
//import { validNumber,validDecNumber,validOnlyLetters } from '../../Regex';
import PropTypes from 'prop-types';

TableRowCellFromTill.propTypes = {
    id: PropTypes.any, // Replace 'any' with the specific type you expect
    ref: PropTypes.any, // Replace 'any' with the specific type you expect
    nameFrom: PropTypes.any, // Replace 'any' with the specific type you expect
    nameTo: PropTypes.any, // Replace 'any' with the specific type you expect
    valueFrom: PropTypes.any, // Replace 'any' with the specific type you expect
    valueTo: PropTypes.any, // Replace 'any' with the specific type you expect
    readOnly: PropTypes.any, // Replace 'any' with the specific type you expect
    onChange: PropTypes.any, // Replace 'any' with the specific type you expect
    maxLen: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function TableRowCellFromTill (props) {
    const {t} = useTranslation();
    const {id,ref,nameFrom,nameTo,valueFrom,valueTo,readOnly,onChange,maxLen} = props;

    function changeHandler (e) {
        //if (!validDecNumber.test(e.target.value)) return;
        log.debug(e);
        onChange({id: id,name: e.target.name, value: e.target.value});    
    }

    const vFrom = (readOnly && (valueFrom==="-1" || valueFrom===-1)) ? "" : valueFrom;
    const vTo = (readOnly && (valueFrom==="-1" || valueFrom===-1 || valueTo==="-1" || valueTo===-1)) ? "" : valueTo;

    return (
             <td>
                <div className='row'>
                    <div className='col-md-5'>
                        <input className="form-control border-0" ref={ref} readOnly={readOnly} name={nameFrom} value={vFrom} onChange={changeHandler} maxLength={maxLen}/>
                    </div>
                    <div className='col-md-1'>
                        {
                            !(readOnly && (valueFrom==="-1" || valueFrom===-1)) && <div>{t("label.till")}</div>
                        }
                    </div>
                    <div className='col-md-5'>
                        <input className="form-control border-0" ref={ref} readOnly={readOnly} name={nameTo} value={vTo} onChange={changeHandler} maxLength={maxLen}/>
                    </div>
                </div>
             </td>
             );
}