import React, { useContext} from 'react'
import { ProductExtraContext } from './RegisterProduct'
import TableRowCell from '../layout/tables/TableRowCell';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
import FormButton from '../layout/forms/FormButton';
import TableLayout from '../layout/tables/TableLayout';
import PropTypes from 'prop-types';

ListBottleTypes.propTypes = {
    bottleTypes: PropTypes.any, // Replace 'any' with the specific type you expect
    hideAddButton: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function ListBottleTypes(props) {
    const {bottleTypes,hideAddButton} = props;
    const {viewBottleType,addBottleType} = useContext(ProductExtraContext);
    const columns=["label.volume","label.currentNetPrice","label.currentStock","label.taric"];

    function displayData () {
        if (!bottleTypes) return ('');

        const ret = bottleTypes.map((bottleType) => {
            return (
                <tr key={bottleType.id}>
                    <TableRowCell value={bottleType.volume} onClick={viewBottleType} id={bottleType.id}/>
                    <TableRowCell label={bottleType.currentNetPrice}/>
                    <TableRowCell label={bottleType.currentStock}/>
                    <TableRowCell value={bottleType.taric}/>
                </tr>
            )
        }) 
        return (ret);
    }

    return (
        <div className="ListWineBottleTypes">
            <div className='row'>
                <FormLineParagHeader col='3' label={"title.volumes"}/>
                <div className='col-md-2'>
                {
                    !hideAddButton && <FormButton onClick={addBottleType} label='button.add'/>
                }
                </div>
            </div>
            <TableLayout columns={columns} displayData={displayData}/>
        </div>
    )
}
