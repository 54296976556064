import React, {useContext,useState,useEffect} from 'react';
import { AppContext } from '../Drinkereez';
import FormLineSelect from '../layout/forms/FormLineSelect';
import ShowProducts from './ShowProducts';
import { CallgetAllProducers } from '../Producer/CallProducer';
import {log} from '../../Log';

export default function ProductsPage() {
    const {login,producer,setProducer} = useContext(AppContext);
    const [producers,setProducers] = useState ();

    log.debug("ProductsPage");
    log.debug(producer);

    useEffect(() => {
        getAllProducers();
      }, []);

    function getAllProducers() {
        log.debug("ShowProducers - getAllProducers");
        CallgetAllProducers(login,response => {
            if (response.err || response.ret?.err) {
                log.debug("error");
                return;
            }
            log.debug(response);
            setProducers(response.producers);
        });
    }  

    function onChangeProducerHandler (e) {
        if (producers) setProducer(producers.find(({id}) => id === e.value))
    }

    function createList() {
        if (!producers) return [];
    
        return producers.map(producer => ({
            value: producer.id,
            label: producer.company.name
        }));
    }

    /*function createList () {
        const list = [];
        if (producers) producers.map((producer) => {list.push({value:producer.id,label:producer.company.name})});
        return list;
    }*/

    function getId () {
        if (producer)
            return producer.id
        return '';
    }

    return (
        <div className='productsPage'>
            <FormLineSelect name="producer" value={getId()} label="label.producer" onChange={onChangeProducerHandler} list={createList()} freeList={true}/>
            {
                producer && <ShowProducts/>
            }
        </div>
    )
}       