import React, { useState} from 'react'
import FormLineSelect from '../layout/forms/FormLineSelect'
import FormLineInput from '../layout/forms/FormLineInput'
import resources from '../../resources/lists/dropdownlist.json'
import {CallOldCalculator} from './CallCalculator'
import FormLineErrorMessage from '../layout/forms/FormLineErrorMessage';
import FormButton from '../layout/forms/FormButton';
import {log} from '../../Log';
import PropTypes from 'prop-types';

CalculatorPostForm.propTypes = {
    fillReturnCalc: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function CalculatorPostForm(props) {
    const {fillReturnCalc} = props;
    const [calcInput, setCalcInput] = useState({
        country: '',
        productType: '',
        degrees: '',
        plato: '',
        volume: '',
        packageType: '',
        annualProduction: '',
        netPrice: '',
        totalPrice: '',
    });
    const [error, setError] = useState({                
        errorMessage: '',
        errorJSON: {}
    });

    function changeHandler (e) {
        const changes = {[e.name]: e.value};
        setCalcInput({...calcInput,...changes});
    }

    function checkInputFields () {
        if (calcInput.country==="" || calcInput.productType==="" || calcInput.degrees==="" || calcInput.volume==="" || calcInput.packageType==="") {
            setError({errorMessage: "Fill in all required fields",errorJSON: calcInput});
            return false;
        }
        if (calcInput.netPrice==="" && calcInput.totalPrice==="") {
            setError({errorMessage: "At Least of the net or total price need to be filled in"});
            return false;
        }
        return true;
    }

    function submitHandler (e) {
        e.preventDefault();
        setError({errorMessage: '',errorJSON: {}});
        log.debug(calcInput);
        if (!checkInputFields ()) {
            return;
        }
        CallOldCalculator(calcInput,response => {
            if (response.err) {
                setError({errorMessage: response.err,errorJSON : calcInput});
            } else {
                fillReturnCalc(response);
            }
        });
    }

    return (
        <form onSubmit={submitHandler}>
            <div className='row'>
                <FormLineSelect col="2" list={resources.calculatorCountries} label="label.country" name="country" value={calcInput.country} onChange={changeHandler}/>
                <FormLineSelect col="3" list={resources.productTypesBE} label="label.productType" name="productType" value={calcInput.productType} onChange={changeHandler}/>
                <FormLineSelect col="3" list={resources.packageTypes} label="label.packageType" name="packageType" value={calcInput.packageType} onChange={changeHandler}/>
            </div>
            <div className='row'>
                <FormLineInput col="2" type="decNumber" label="label.degrees" name="degrees" value={calcInput.degrees} onChange={changeHandler}/>
                <FormLineInput col="2" type="decNumber" label="label.plato" name="plato" value={calcInput.plato} onChange={changeHandler}/>
                <FormLineInput col="2" type="decNumber" label="label.volumeL" name="volume" value={calcInput.volume} onChange={changeHandler}/>
                <FormLineInput col="3" type="number" label="label.annualProductionHL" name="annualProduction" value={calcInput.annualProduction} onChange={changeHandler}/>
            </div>
            <div className='row'>
                <FormLineInput col="2" type="decNumber" label="label.netPrice" name="netPrice" value={calcInput.netPrice} onChange={changeHandler}/>
                <FormLineInput col="2" type="decNumber" label="label.totalPrice" name="totalPrice" value={calcInput.totalPrice} onChange={changeHandler}/>
            </div>
            <FormLineErrorMessage message={error.errorMessage} errorJSON={error.errorJSON}/>
            <FormButton type="submit" label='button.submit'/>
            <FormButton type="reset" level='secondary' label='button.reset'/>
        </form> 
    )
}
