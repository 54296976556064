import React, { useRef,useState } from 'react';
import ClientServicesListAdmin from './ClientServicesListAdmin';
import ShowClientService from './ShowClientService';
import {log} from '../../Log';

export default function ClientServicesPageAdmin() {
    const [showClientService, setShowClientService] = useState(false);
    const [clientService, setClientService] = useState(null);
    const clientServicesListRef = useRef();

    function showService (cS) {
        setShowClientService(true);
        setClientService(cS);
    }

    function closeService () {
        setShowClientService(false);
        reloadData();
    }

    function reloadData() {
        if (clientServicesListRef.current) {
            clientServicesListRef.current.reloadData();
        }
    }
    
    return (
        <div>
            <ClientServicesListAdmin ref={clientServicesListRef} showService={showService}/>
            {
               showClientService && <ShowClientService closeScreen={closeService} clientService={clientService}/> 
            }
        </div>
    )
}