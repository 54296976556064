import React, { useContext } from 'react'
import { useTranslation } from "react-i18next";
import { AppContext } from '../Drinkereez';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

ClientPortalNavBar.propTypes = {
    navOptionClicked: PropTypes.func.isRequired // Expecting a function type
};

export default function ClientPortalNavBar(props) {
    const { navOptionClicked } = props;
    const { t } = useTranslation();
    const { user,client } = useContext(AppContext);
    const navigate = useNavigate();

    const navItems = [
        { id: 'orders', label: t('tab.navbar_orders'), services: ['CLIENT_SERVICE_IMPORT'] },
        { id: 'products', label: t('tab.navbar_products'), services: ['CLIENT_SERVICE_IMPORT'] },
        { id: 'tools', label: t('tab.navbar_tools'), isDropdown: true,
                    dropdownItems: [
                        { id: 'calculator', label: t('tab.navbar_calculator'), services: ['CLIENT_SERVICE_IMPORT'] },
                        { id: 'producerData', label: t('tab.navbar_producer_data'), services: ['admin'] },
                        { id: 'servicesList', label: t('tab.navbar_services_list') }, 
                        { id: 'clientData', label: `${client.company.name}` }, 
                        { id: 'userData', label: `${user.person.firstName} ${user.person.lastName}` },
                        { id: 'logout', label: t('tab.navbar_logout')}
                    ]}
    ];

    function optionClicked(e) {
        const option = e.target.id;
        if (option === 'logout') {
            navOptionClicked(option)
        } else {
            navigate(`/portal/${option}`);
        }
    }

    function clientHasRole(services) {
        // Check if roles is null or empty, or if client.roles is undefined or not an array
        if (!services || services.length === 0)
            return true;
        if (!client.services || client.services.length === 0)
            return false;
        return services.some(service => client.services.includes(service));
    }
    
    
    function renderNavItem(item) {
        // Check if client has at least one of the roles required to see the item
        if (!clientHasRole(item.services)) {
            return null;
        }

        if (item.dropdownItems) {
            return (
                <li key={item.id} className="nav-item">
                    <a id={item.id} className="nav-link link-dark dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false" href="#nogo">{item.label}</a>
                    <ul className="dropdown-menu">
                        {item.dropdownItems.map(subItem => {
                            if (!clientHasRole(subItem.services)) {
                                return null;
                            }
                            return <li key={subItem.id}><a className="dropdown-item" href="#nogo" id={subItem.id} onClick={optionClicked}>{subItem.label}</a></li>;
                        })}
                    </ul>
                </li>
            );
        }

        return (
            <li key={item.id} className="nav-item">
                <a id={item.id} className="nav-link link-dark" href="#nogo" onClick={optionClicked}>{item.label}</a>
            </li>
        );
    }

    return (
        <ul className="nav nav-tabs nav-justified">
            {navItems.map(renderNavItem).filter(item => item !== null)}
        </ul>
    )
}
