import React, {useState, useContext} from 'react';
import { AppContext } from '../Drinkereez';
import FormLineInput from '../layout/forms/FormLineInput';
import FormLineTextArea from '../layout/forms/FormLineTextArea';
import FormLineSelect from '../layout/forms/FormLineSelect';
import FormLineErrorMessage from '../layout/forms/FormLineErrorMessage';
import FormButton from '../layout/forms/FormButton';
import resources from '../../resources/lists/dropdownlist.json'
import {log} from '../../Log'; 
import { CallUploadXML } from './CallXml';
import PropTypes from 'prop-types';

UploadXML.propTypes = {
    xmlData: PropTypes.any, // Replace 'any' with the specific type you expect
    setXmlData: PropTypes.any, // Replace 'any' with the specific type you expect
    stopShowXml: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function UploadXML(props) {
//    const {xmlData, setXmlData,stopShowXml,xmlSaved} = props;
    const {xmlData, setXmlData,stopShowXml} = props;
    const {login} = useContext(AppContext);

    //const [xmlData, setXmlData] = useState({type: '',name: '',xml: ''});
    const [error, setError] = useState({errorMessage: '',errorJSON: {}});

    function changeHandler (e) {
        const changes = {[e.name]: e.value};
        setXmlData({...xmlData,...changes});
    }

    function doReset () {
        setXmlData({type: '',name: '',xml: ''});
        setError({errorMessage: '',errorJSON: {}});
    }

    function submitHandler (e) {
        setError({errorMessage: '',errorJSON: {}});
        e.preventDefault();

        log.debug("execute await statements");
        log.debug(xmlData);

        CallUploadXML(login,xmlData,response => {
            if (response.err || response.ret?.err) {
                log.debug("error");
                return;
            }
            log.debug(response);
            stopShowXml();
        });
    }

    return (
        <form onSubmit={submitHandler}>
            <div className='row'>
                <FormLineInput col='5' type="input" label="label.name" name="name" value={xmlData.name} onChange={changeHandler} required={true}/>
            </div>
            <div className='row'>
                <FormLineSelect col='5' type="input" list={resources.ExciseXMLTypes} label="label.type" name="type" value={xmlData.type} onChange={changeHandler} required={true}/>
            </div>
            <div className='row'>
                <FormLineTextArea type="input" rows='5' label="label.xml" name="xml" value={xmlData.xml} onChange={changeHandler} required={true}/>
            </div>
            <FormLineErrorMessage message={error.errorMessage} errorJSON={error.errorJSON}/>
            <FormButton type="submit" label='button.submit'/>
            <FormButton type="reset" onClick={doReset} label='button.reset' level='secondary'/>
            <FormButton type="cancel" onClick={stopShowXml} label='button.cancel' level='secondary'/>
        </form> 
    )
}