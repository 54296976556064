import React from 'react';
import FormLineInput from '../layout/forms/FormLineInput';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
import PropTypes from 'prop-types';

ShowCompany.propTypes = {
    name: PropTypes.string,
    companyId: PropTypes.string,
    VATNumber: PropTypes.string,
    header: PropTypes.string,
    updateCompany: PropTypes.func.isRequired,
    editMode: PropTypes.bool
};

ShowCompany.defaultProps = {
    editMode: false
};

export default function ShowCompany(props) {
    const { name, companyId, VATNumber, header, updateCompany, editMode } = props;

    function changeHandler(e) {
        const { name, value } = e;
        updateCompany({ [name]: value });
    }

    return (
        <div className="CompanyData mb-5">
            <FormLineParagHeader label={header} />
            <div className='row'>
                <FormLineInput col={6} label="label.companyName" name="name" readOnly={!editMode} value={name} onChange={changeHandler} required={true} />
                <FormLineInput col={3} label="label.VATNumber" name="VATNumber" readOnly={!editMode} value={VATNumber} onChange={changeHandler} required={true} />
                <FormLineInput col={3} label="label.companyId" name="companyId" readOnly={!editMode} value={companyId} onChange={changeHandler} required={true} />
            </div>
        </div>
    );
}
