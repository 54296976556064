import React from 'react'
import FormLineInput from '../layout/forms/FormLineInput';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
import Address from './Address';
import PropTypes from 'prop-types';

Trader.propTypes = {
    trader: PropTypes.any, // Replace 'any' with the specific type you expect
    setTrader: PropTypes.any, // Replace 'any' with the specific type you expect
    header: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function Trader(props) {
    const {trader,setTrader,header} = props;

    function changeHandler (e) {
        setTrader({...trader,[e.name]: e.value});
    }

    return (
        <div className="TraderData mb-5">
            <FormLineParagHeader label={header}/>
            <div className='row'>
                <FormLineInput col='4' type="text" label="label.companyName" name="name" value={trader.name} onChange={changeHandler} readOnly={true}/>
                <FormLineInput col='4' type="text" label="label.VATNumber" name="VATNumber" value={trader.VATNumber} onChange={changeHandler} readOnly={true}/>
                <FormLineInput col='4' type="text" label="label.companyId" name="companyId" value={trader.companyId} onChange={changeHandler} readOnly={true}/>
            </div>
            <div className='row'>
                <FormLineInput col='4' type="text" label="label.traderId" name="traderId" value={trader.traderId} onChange={changeHandler} readOnly={true}/>
                <FormLineInput col='4' type="text" label="label.exciseNumber" name="exciseNumber" value={trader.exciseNumber} onChange={changeHandler} readOnly={true}/>
                <FormLineInput col='4' type="text" label="label.language" name="language" value={trader.language} onChange={changeHandler} readOnly={true}/>
            </div>
            <Address {...trader.mainAddress} header="title.companyAddress" readOnly={true}/>
        </div>
    )
}