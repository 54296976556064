import React, {useState,useContext,useCallback,useMemo } from 'react'
import { AppContext } from '../Drinkereez';
import Address from '../General/Address';
import ShowCompany from '../General/ShowCompany';
import ContactPerson from '../General/ContactPerson';
import FormLineInput from '../layout/forms/FormLineInput';
import FormLineErrorMessage from '../layout/forms/FormLineErrorMessage';
import FormLineSuccessMessage from '../layout/forms/FormLineSuccessMessage';
import FormButton from '../layout/forms/FormButton';
import {log} from '../../Log';
import {CallSaveClient} from './CallClient'
import PropTypes from 'prop-types';

UpdateClient.propTypes = {
    stopShowClient: PropTypes.func.isRequired, 
    initialEditMode: PropTypes.bool,
    showContactperson: PropTypes.bool 
};

UpdateClient.defaultProps = {
    initialEditMode: false,
    showContactperson: true
};

export default function UpdateClient({stopShowClient,initialEditMode,showContactperson}) {
    const {login,setClient,client} = useContext(AppContext);
    const [editMode, setEditMode] = useState(initialEditMode);
    const [locClient, setLocClient] = useState(client);
    const [error, setError] = useState({errorMessage: '',errorJSON: {}});
    const [success, setSuccess] = useState({message: '',extraMessage: ''});

    const updateCompany = useCallback((change) => {
        setLocClient(prev => ({ ...prev, company: { ...prev.company, ...change } }));
    }, []);

    const changeHandler = useCallback((e) => {
        const { name, value } = e;
        setLocClient(prev => ({ ...prev, [name]: value }));
    }, []);

    const updateContactPerson = useCallback((change) => {
        setLocClient(prev => ({...prev,mainContact: {...prev.mainContact,person: { ...prev.mainContact.person, ...change }}}));
    }, []);

    const updateAddress = useCallback((change) => {
        setLocClient(prev => ({ ...prev, company: { ...prev.company,mainAddress: { ...prev.company.mainAddress, ...change} } }));
    }, []);

    const saveHandler = useCallback(() => {
        log.debug("UpdateClient - saveHandler");
        log.debug(locClient);
        CallSaveClient(login, locClient, response => {
            if (response.err || response.ret?.err) {
                log.debug("error");
                setError({errorMessage: response.err + ' ' + response.ret?.err, errorJSON: {login, locClient}});
                return;
            }
            log.debug("saved");
            log.debug(response);
            setClient(response);
            stopShowClient();
        });
    }, [login, locClient, stopShowClient, setClient]);
    
    const editSaveButton = useMemo(() => editMode ?
        <FormButton onClick={saveHandler} label='button.save' /> :
        <FormButton onClick={() => setEditMode(true)} label='button.edit' />,
    [editMode, saveHandler]);

    return (
        <div className='showClient'>
            <div className='row'>
                <FormLineInput col={6} maxLength={50} readOnly={!editMode} label="label.brandName" name="brandName" value={locClient.brandName} onChange={changeHandler} required={true}/>
                <FormLineInput col={6} maxLength={50} readOnly={!editMode} label="label.exciseNumber" name="exciseNumber" value={locClient.exciseNumber} onChange={changeHandler} required={true}/>
            </div>
            <ShowCompany {...locClient.company} updateCompany={updateCompany} editMode={editMode}/>
            <Address header="title.companyAddress" {...locClient.company.mainAddress} updateAddress={updateAddress} readOnly={!editMode}/>
            {
                showContactperson && <ContactPerson header="title.personalData" updateContactPerson={updateContactPerson} readOnly={!editMode} {...locClient.mainContact.person}/>
            }
            <FormLineErrorMessage message={error.errorMessage} errorJSON={error.errorJSON}/>
            <FormLineSuccessMessage message={success.message} extraMessage={success.extraMessage}/> 
            {editSaveButton}           
            <FormButton onClick={stopShowClient} level='secondary' label='button.cancel'/>
        </div>
    )
}
