import {useCallBackend} from '../General/CallBackend';
import {log} from '../../Log';
import uploadFileTest from '../../resources/test/uploadFileTest.json';

export function saveMetaData(login,filename,directory,fileType,responseFunc) {
  const testMode = process.env.REACT_APP_TESTMODE === 'Y';
  log.debug ("save Metadata");
  const body = {
    req: {...login, action: 1},
    filename: filename,
    directory: directory,
    fileType: fileType
  };

  log.debug(body);

  // Make the backend call if not in test mode
  if (!testMode) {
    //CallBackendConf("FileUpload", body, response => {
    //    responseFunc(response);
    //});
  }
}

export function CallUploadFile (login,file,subdrirectory,responseFunc) {
  const { CallBackendConf } = useCallBackend();
  const testMode = process.env.REACT_APP_TESTMODE === 'Y';
  if (!file) {
    alert('Please select a file to upload.');
    return;
  }


  log.debug("subdrirectory : " + subdrirectory);

  const formData = new FormData();
  formData.append('file', file);
  formData.append('subdirectory', subdrirectory);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  if (testMode) {
    log.debug("test")
    responseFunc(uploadFileTest.fileUploaded);
    return;
  }

  // Here we're using your backend call function
  CallBackendConf('Upload', formData, config, (response) => {
    if (response.err) {
      alert('Upload error: ' + response.err);
    } else {
      console.log('Upload success:', response);
      //saveMetaData();
    }
    responseFunc(response);
  });
}