import React from 'react'
import logo from '../images/drinkereez_barrel_white.png'

export default function Footer() {
  return (
    <div className="footer fixed-bottom row bg-white">
        <div className='col-5'></div>
        <div className='col-2'><img className="img-thumbnail border-0 footer-logo" src={logo} alt=''/></div>
        <div className='col-5'></div>
    </div>
  )
}
