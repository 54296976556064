import React, {useContext} from 'react'
import { ProductContext } from '../ShowProducts';
import FormLineInput from '../../layout/forms/FormLineInput';
import FormLineSelect from '../../layout/forms/FormLineSelect';
import resources from '../../../resources/lists/dropdownlist.json'
import PropTypes from 'prop-types';

RegisterSpiritProduct.propTypes = {
    editMode: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function RegisterSpiritProduct(props) {
    const {editMode} = props;
    const {product, setProduct} = useContext(ProductContext);

    function changeHandler (e) {
        setProduct({...product,mainType: 'PRODUCT_TYPE_GENERAL_SPIRIT',[e.name]: e.value});
    }

    function resouceSubType (type) {
        if (type==="SPIRIT_TYPE_ABSINTHE") {
            return (null);
        }
        else if (type==="SPIRIT_TYPE_ARMAGNAC") {
            return (resources.ArmagnacSubTypes);
        }
        else if (type==="SPIRIT_TYPE_BRANDY") {
            return (null);
        }
        else if (type==="SPIRIT_TYPE_CALVADOS") {
            return (resources.CalvadosSubTypes);
        }
        else if (type==="SPIRIT_TYPE_COGNAC") {
            return (resources.CognacSubTypes);
        }
        else if (type==="SPIRIT_TYPE_EAUDEVIE") {
            return (resources.EauDeVieSubTypes);
        }
        else if (type==="SPIRIT_TYPE_GIN") {
            return (resources.GinSubTypes);
        }
        else if (type==="SPIRIT_TYPE_GRAPPA") {
            return (resources.GrappaSubTypes);
        }
        else if (type==="SPIRIT_TYPE_LIQUEUR") {
            return (resources.LiqueurSubTypes);
        }
        else if (type==="SPIRIT_TYPE_MARC") {
            return (null);
        }
        else if (type==="SPIRIT_TYPE_RUM") {
            return (resources.RumSubTypes);
        }
        else if (type==="SPIRIT_TYPE_VODKA") {
            return (resources.VodkaSubTypes);
        }
        else if (type==="SPIRIT_TYPE_WHISKY") {
            return (resources.WhiskySubTypes);
        }
        else if (type==="SPIRIT_TYPE_OTHER") {
            return (null);
        } 
        else {
            return (null);
        }
    }

    return (
        <div className="RegisterSpiritProduct">
        <div className='row'>
            <FormLineInput col='6' type="text" readOnly={!editMode} label="label.productName" name="productName" value={product.productName} onChange={changeHandler} required={true}/>
            <FormLineSelect col='3' list={resources.SpiritTypes} readOnly={!editMode} name="subType" label="label.type" value={product.subType} onChange={changeHandler} required={true}/>
            {
                resouceSubType(product.subType)!==null && <FormLineSelect col='3' list={resouceSubType(product.subType)} readOnly={!editMode} name="subType2" label="label.subType" value={product.subType2} onChange={changeHandler} required={true}/>
            }
        </div>
        <div className='row'>
            <FormLineInput col='4' type="text" readOnly={!editMode} label="label.brandName" name="brandName" value={product.brandName} onChange={changeHandler} required={true}/>
            <FormLineSelect col='2' list={resources.EPC} readOnly={!editMode} name="epc" label="label.epc" value={product.epc} onChange={changeHandler} required={true}/>
            <FormLineInput col='2' maxLength={5} type="text" readOnly={!editMode} label="label.alcoholPerc" name="alcoholPercentage" value={product.alcoholPercentage} onChange={changeHandler} required={true}/>
            <FormLineInput col='2' maxLength={20} type="text" readOnly={!editMode} label="label.ageStatement" name="vintage" value={product.vintage} onChange={changeHandler} required={true}/>
            <FormLineSelect col='2' list={resources.yesno} readOnly={!editMode} name="bio" label="label.bio" value={product.bio} onChange={changeHandler} required={true}/>
        </div>
        <div className='row'>
            {
                product && product.indicationOfOrigin && product.indicationOfOrigin!=="" && product.indicationOfOrigin!=="GEO_ID_SPIRIT_NONE" && 
                <>
                    <FormLineInput col='2' type="text" readOnly={!editMode} label="label.natDenomination" name="natDenomination" value={product.natDenomination} onChange={changeHandler} required={true}/>
                    <FormLineInput col='8' type="text" readOnly={!editMode} label="label.wineDenomination" name="denomination" value={product.denomination} onChange={changeHandler} required={true}/>
                </>
            }
        </div>
        <div className='row'>
            <FormLineInput type="text" col='6' readOnly={!editMode} label="label.region" name="region" value={product.region} onChange={changeHandler} required={true}/>
            <FormLineInput type="text" col='6' readOnly={!editMode} label="label.subregion" name="wineRegion" value={product.wineRegion} onChange={changeHandler} required={true}/>
        </div>
        <div className='row'>
            <FormLineInput type="text" col='12' readOnly={!editMode} label="label.colour" name="colour" value={product.colour} onChange={changeHandler} required={true}/>
            <FormLineInput type="text" col='12' readOnly={!editMode} label="label.aroma" name="aroma" value={product.aroma} onChange={changeHandler} required={true}/>
            <FormLineInput type="text" col='12' readOnly={!editMode} label="label.taste" name="flavour" value={product.flavour} onChange={changeHandler} required={true}/>
            <FormLineInput col="12" type="text" readOnly={!editMode} label="label.rawMaterials" name="grapes" value={product.grapes} onChange={changeHandler} required={true}/>
            <FormLineInput type="text" col='12' readOnly={!editMode} label="label.pairing" name="pairing" value={product.pairing} onChange={changeHandler} required={true}/>
        </div>
        <div className='row'>
            <FormLineInput type="text" maxLength={100}readOnly={!editMode} label="label.shortDescription" name="shortDescription" value={product.shortDescription} onChange={changeHandler} required={true}/>
        </div>
    </div> 
    )
}
