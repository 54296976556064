import React, {useState,useContext} from 'react'
import { AppContext } from '../Drinkereez';
import ContactPerson from '../General/ContactPerson'
import FormLineInput from '../layout/forms/FormLineInput'
import FormLineSelect from '../layout/forms/FormLineSelect'
import resources from '../../resources/lists/dropdownlist.json';
import { CallSaveUser,CallChangePassword } from './CallUser';
import {log} from '../../Log';
import FormLineErrorMessage from '../layout/forms/FormLineErrorMessage';
import FormButton from '../layout/forms/FormButton';
import PropTypes from 'prop-types';

RegisterUser.propTypes = {
    user: PropTypes.any, // Replace 'any' with the specific type you expect
    setUser: PropTypes.any, // Replace 'any' with the specific type you expect
    newRecord: PropTypes.any, // Replace 'any' with the specific type you expect
    userSaved: PropTypes.any, // Replace 'any' with the specific type you expect
    stopShowUser: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function RegisterUser(props) {
    const {login} = useContext(AppContext);
    const {user,setUser,newRecord,userSaved,stopShowUser} = props;
    const [editMode,setEditMode] = useState(newRecord);
    const [password,setPassword] = useState();
    const [error, setError] = useState({errorMessage: '',errorJSON: {}});

    function editHandler () {
        setEditMode(true);
    }

    function saveHandler () {
        log.debug("RegisterUser - saveHandler");
        log.debug(user);
        CallSaveUser(login,user,response => {
            if (response.err || response.ret?.err) {
                log.debug("error");
                setError({errorMessage: response.err + ' ' + response.ret?.err, errorJSON: {login,user}});
                return;
            }
            log.debug(response);
            userSaved();           
        });
    }

    function cancelHandler () {
        stopShowUser();
    }

    function updateContactPerson (change) {
        if (!user.person) {
            setUser({...user,person:{}});
        }
        setUser({...user,person:{...user.person,...change}});
    }

    function changeHandler (e) {
        setUser({...user,[e.name]: e.value});
    }

    function passwordChangeHandler (e) {
        setPassword(e.value);
        if (!user.id) {
            log.debug("add password");
            setUser({...user,password:e.value});
        }
    }

    function changePassword () {
        log.debug("RegisterUser - changePassword");
        log.debug(user.id + " " + password);
//        CallAdminChangePassword(login,user.id,password,response => {
        CallChangePassword(user.id,password,login,response => {
            if (response.err || response.ret?.err) {
                log.debug("error");
                setError({errorMessage: response.err + ' ' + response.ret?.err, errorJSON: {login,id:user.id,password}});
                return;
            }
            log.debug(response);
            setPassword('');  
            setError({errorMessage: '',errorJSON: {}});  
            setEditMode(false);   
        });
    }

    return (
        <div className='registerUser'>
            <div className='row'>
                <FormLineInput col="6" maxLength={50} type="text" readOnly={!editMode} label="label.username" name="username" value={user.username} onChange={changeHandler} required={true}/>
                <FormLineSelect col="6" list={resources.userTypes} type="text" readOnly={!editMode} label="label.type" name="userType" value={user.userType} onChange={changeHandler} required={true}/>
            </div>
            <ContactPerson header="title.personalData" updateContactPerson={updateContactPerson} readOnly={!editMode} {...user.person}/>
            <div className='row'>
                {
                    editMode && <FormLineInput col="4" maxLength={25} type="password" readOnly={!editMode} label="label.password" name="password" onChange={passwordChangeHandler} required={true}/>
                }
                {
                    editMode && !newRecord && <div className='col-md-2'><FormButton onClick={changePassword} label='button.modifyPassword'/></div>
                }
            </div>
            <FormLineErrorMessage message={error.errorMessage} errorJSON={error.errorJSON}/>
            {
                !editMode && <FormButton onClick={editHandler} label='button.edit'/>
            }
            {
                editMode && <FormButton onClick={saveHandler} label='button.save'/>
            }
            <FormButton onClick={cancelHandler} level='secondary' label='button.cancel'/>
        </div>
    )
}
