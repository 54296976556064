import React, { useState,useRef } from 'react';
import ClientServicesList from './ClientServicesList';
import RegisterClientService from './RegisterClientService';
import PropTypes from 'prop-types';
import FormButton from '../layout/forms/FormButton';
import ClientServicesListUser from './ClientServicesListUser';
import ShowClientService from './ShowClientService';
import {log} from '../../Log';

ClientServicesPage.propTypes = {
    //navOptionClicked: PropTypes.func.isRequired // Expecting a function type
};

export default function ClientServicesPage() {
    const [showAddService, setShowAddService] = useState(false);
    const [showClientService, setShowClientService] = useState(false);
    const [clientService, setClientService] = useState(null);
    const clientServicesListRef = useRef();

    function showService (cS) {
        setShowClientService(true);
        setClientService(cS);
    }

    function closeService () {
        setShowClientService(false);
    }

    function addService () {
        setShowAddService(true);
    }

    function closeAddService () {
        setShowAddService(false);
        reloadData();
    }

    function reloadData() {
        if (clientServicesListRef.current) {
            clientServicesListRef.current.reloadData();
        }
    }

    return (
        <div>
            <ClientServicesList/>
            <ClientServicesListUser ref={clientServicesListRef} showService={showService} />
            {
                !showClientService && !showAddService && <FormButton onClick={addService} label='button.add'/>
            }
            {
               showAddService && <RegisterClientService closeScreen={closeAddService}/> 
            }
            {
               showClientService && <ShowClientService closeScreen={closeService} clientService={clientService}/> 
            }
        </div>
    )
}