import React, { useState,useContext,useCallback } from 'react';
import { AppContext } from '../Drinkereez';
import {log} from '../../Log';
import { CallUploadFile } from '../UploadFile/CallUploadFile'
import { CallSaveClientService } from './CallClient';
import PropTypes from 'prop-types';
import FormButton from '../layout/forms/FormButton';
import FormLineSelect from '../layout/forms/FormLineSelect';
import resources from '../../resources/lists/dropdownlist.json';
import FormLineTextArea from '../layout/forms/FormLineTextArea';
import FormLineFileInput from '../layout/forms/FormLineFileInput';
import FormLineErrorMessage from '../layout/forms/FormLineErrorMessage';

RegisterClientService.propTypes = {
    closeScreen: PropTypes.func.isRequired // Expecting a function type
};

export default function RegisterClientService(props) {
    const {login,client} = useContext(AppContext);
    const {closeScreen} = props;
    const [service, setService] = useState({});
    const [contractFile, setContractFile] = useState(null);
    const [error, setError] = useState({errorMessage: '',errorJSON: {}});

    function callClientServ (clientService) {
        CallSaveClientService(login,clientService,response => {
            if (response.err) {
                log.debug("error : " + response.err);
                setError({errorMessage: response.err, errorJSON:{clientService,login}});
                return;
            } else {
                log.debug("ok" + response);
                setService(response); 
                closeScreen(); 
            }
        })
    }

    function saveService () {
        CallUploadFile(login,contractFile,client.id,response => {
            if (response.err) {
                log.debug("error : " + response.err);
                setError({errorMessage: response.err, errorJSON:{contractFile,login}});
                return;
            } else {
                log.debug("ok" + response);
                const clientService = { ...service, 
                    client: client,
                    state: "DRINKEREEZ_SERVICE_STATE_REQUESTED",
                    contract : {
                        url : response.path,
                        type : response.fileExtension,
                        name : response.name 
                    }};
                callClientServ (clientService); 
            }
        });
    }

    const changeHandler = e => {
        if (e.target) {
            setService({ ...service, [e.target.name] : e.target.value });
        } else {
            setService({ ...service, [e.name] : e.value });    
        }
    };

    return (
        <div>
            <div className='row'>
                <FormLineSelect col={3} list={resources.drinkereezServices} name="type" label="label.serviceType" value={service.type} onChange={changeHandler} required={true}/>
            </div>
            <FormLineTextArea name="motivation" rows={5} label="label.motivation" value={service.motivation} onChange={changeHandler} required={true}/>
            <FormLineFileInput name="contract" label="label.contract" setFile={setContractFile} required={true}/>
            <FormLineErrorMessage message={error.errorMessage} errorJSON={error.errorJSON}/>
            <FormButton onClick={saveService} label='button.save'/>
            <FormButton onClick={closeScreen} level="secondary" label='button.cancel'/>
        </div>
    )
}