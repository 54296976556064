import React from 'react';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';

FormLineErrorMessage.propTypes = {
    message: PropTypes.any, // Replace 'any' with the specific type you expect
    errorJSON: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function FormLineErrorMessage (props) {
    const {t} = useTranslation();
    const {message,errorJSON} = props;
    const messageEmpty = message === "";
    let showDetails = false; // eslint-disable-line no-unused-vars

    function detailsClicked() {
        showDetails = true;
    }

    function printError () {
        if (messageEmpty) return ("");
        else 
        return (
            <div className="alert alert-danger" role="alert">
                <div>{t(message)} <a onClick={detailsClicked} data-bs-toggle="collapse" href="#collapseError" className="link-danger" aria-expanded="false" aria-controls="collapseError">details</a></div>
                <div className="collapse" id="collapseError">{JSON.stringify(errorJSON,null,2)}</div>
            </div>
        )
    }

    return (
        printError() 
    );  
}