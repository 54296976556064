import {useCallBackend} from '../General/CallBackend';
import {log} from '../../Log';
import exciseTest from '../../resources/test/exciseTest.json';

export function CallGetAllIE801 (login,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';
    log.debug("send JSON get all IE801");

    const body = { 
        req : {...login,action: 12}
    }

    log.debug(body);
    log.debug("post");

    if (testMode) {
        responseFunc (exciseTest.ie801s);
    }
    else {
        CallBackend ("product",body, response => {
            responseFunc(response);
        });
    }
}