import React, {useState,useContext} from 'react'
import { AppContext } from '../Drinkereez';
import Address from '../General/Address';
import ShowCompany from '../General/ShowCompany';
import ContactPerson from '../General/ContactPerson';
import FormLineInput from '../layout/forms/FormLineInput';
import FormLineErrorMessage from '../layout/forms/FormLineErrorMessage';
import FormLineSuccessMessage from '../layout/forms/FormLineSuccessMessage';
import FormButton from '../layout/forms/FormButton';
import {log} from '../../Log';
import {CallSaveProducer} from './CallProducer'
import {CallSendProducer} from '../Shop/CallShop'
import PropTypes from 'prop-types';

ModifyProducer.propTypes = {
    producer: PropTypes.any, // Replace 'any' with the specific type you expect
    setProducer: PropTypes.any, // Replace 'any' with the specific type you expect
    stopShowProducer: PropTypes.any, // Replace 'any' with the specific type you expect
    producerSaved: PropTypes.any, // Replace 'any' with the specific type you expect
    editMode: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function ModifyProducer(props) {
    const {login,user} = useContext(AppContext);
    const {producer,setProducer,stopShowProducer,producerSaved} = props;
    const [editMode, setEditMode] = useState(props.editMode);
    const [error, setError] = useState({errorMessage: '',errorJSON: {}});
    const [success, setSuccess] = useState({message: '',extraMessage: ''});

    function updateCompany (change) {
        const company = {...producer.company,...change};
        setProducer({...producer,company});
    }

    function changeHandler (e) {
        setProducer({...producer,[e.name]: e.value});
    }

    function updateContactPerson (change) {
        const maincontact = {...producer.mainContact,person:{...producer.mainContact.person,...change}}
        setProducer({...producer,mainContact:maincontact});
    }

    function updateAddress (change) {
        const address = {...producer.company.mainAddress,...change}
        updateCompany({mainAddress:address});
    }

    function editHandler () {
        setEditMode(true);
    }

    function saveHandler () {
        log.debug("ModifyProducer - saveHandler");

        CallSaveProducer(login,producer,response => {
            if (response.err || response.ret?.err) {
                log.debug("error");
                setError({errorMessage: response.err + ' ' + response.ret?.err, errorJSON: {login,producer}});
                return;
            }
            log.debug(response);
            producerSaved();           
        });
    }

    function cancelHandler () {
        stopShowProducer();
    }

    function shopHandler () {
        CallSendProducer(producer.id,login,response => {
            if (response.err || response.ret?.err) {
                log.debug("error");
                setError({errorMessage: response.err + ' ' + response.ret?.err, errorJSON: {login,producer}});
                return;
            } else {
                log.debug(response);
                setSuccess({message: 'Producer send to PrestaShop'});
                return;
            }
        });
    }

    return (
        <div className='showProducer'>
            <div className='row'>
                <FormLineInput col="6" maxLength={50} type="text" readOnly={!editMode} label="label.brandName" name="brandName" value={producer.brandName} onChange={changeHandler} required={true}/>
                <FormLineInput col="6" maxLength={50} type="text" readOnly={!editMode} label="label.exciseNumber" name="exciseNumber" value={producer.exciseNumber} onChange={changeHandler} required={true}/>
            </div>
            <ShowCompany {...producer.company} updateCompany={updateCompany} editMode={editMode}/>
            <Address header="title.companyAddress" {...producer.company.mainAddress} updateAddress={updateAddress} readOnly={!editMode}/>
            <ContactPerson header="title.personalData" updateContactPerson={updateContactPerson} readOnly={!editMode} {...producer.mainContact.person}/>
            <FormLineErrorMessage message={error.errorMessage} errorJSON={error.errorJSON}/>
            <FormLineSuccessMessage message={success.message} extraMessage={success.extraMessage}/>            
            {
                !editMode && <FormButton onClick={editHandler} label='button.edit'/>
            }
            {
                editMode && <FormButton onClick={saveHandler} label='button.save'/>
            }
            <FormButton onClick={cancelHandler} level='secondary' label='button.cancel'/>
            {
                user && user.userType==='USER_TYPE_ADMIN' && (!producer.psId || producer.psId===0) && <FormButton onClick={shopHandler} label='button.sendPrestashop'/>
            }
            {
                user && user.userType==='USER_TYPE_ADMIN' && (producer.psId>0) && <FormButton onClick={shopHandler} label='button.updatePrestashop'/>
            }
        </div>
    )
}
