import React from 'react';
import { useTranslation } from "react-i18next";
import settings from '../../../resources/generalSettings.json'
import PropTypes from 'prop-types';

TableRowCellButton.propTypes = {
    id: PropTypes.any, // Replace 'any' with the specific type you expect
    type: PropTypes.any, // Replace 'any' with the specific type you expect
    level: PropTypes.any, // Replace 'any' with the specific type you expect
    label: PropTypes.any, // Replace 'any' with the specific type you expect
    onClick: PropTypes.any, // Replace 'any' with the specific type you expect
    className: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function TableRowCellButton (props) {
    const {t} = useTranslation();
    const {id,type,level,label,onClick,className} = props;
    //const text = value ? value : t(label);

    function readLevel () {
        switch (level) {
            case 'primary' :
                return settings.button_primary;
            case 'secondary' :
                return settings.button_secondary;
            default :
                return settings.button_default;
        }
    }

    function createClassName () {
        const extraClassName = className ? className : 'm-2';
        return "btn " + readLevel() + " " + extraClassName;
    }

    return (
             <td>
                <button id={id} type={type} onClick={onClick} className={createClassName()}>{t(label)}</button>
             </td>
             );
}