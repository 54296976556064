import React, { useState,useContext,useCallback } from 'react';
import { AppContext } from '../Drinkereez';
import {log} from '../../Log';
import PropTypes from 'prop-types';
import FormButton from '../layout/forms/FormButton';
import FormLineSelect from '../layout/forms/FormLineSelect';
import FormLineInput from '../layout/forms/FormLineInput';
import resources from '../../resources/lists/dropdownlist.json';
import FormLineTextArea from '../layout/forms/FormLineTextArea';
import FormLineErrorMessage from '../layout/forms/FormLineErrorMessage';
import { removePublicPrefixURL } from '../GeneralFunc';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
import { CallAcceptDenyClientServices } from './CallClient';
import ShowClientDetails from './ShowClientDetails';

ShowClientService.propTypes = {
    closeScreen: PropTypes.func.isRequired // Expecting a function type
};

export default function ShowClientService({clientService,closeScreen}) {
    const { login,user } = useContext(AppContext);
    const [error, setError] = useState({errorMessage: '',errorJSON: {}});
    const [comment, setComment] = useState("");

    function updateState (accept) {
        CallAcceptDenyClientServices(login,accept,clientService.id,comment,response => {
            if (response.err) {
                log.debug("error : " + response.err);
                setError({errorMessage: response.err, errorJSON:{login,accept,id:clientService.id,comment}});
                return;
            } else {
                log.debug("ok" + response);
                closeScreen(); 
            }
        })
    }

    const changeHandler = e => {
        if (e.name==="comment") {
            setComment(e.value);
        }
    };

    function openContract () {
        window.open(process.env.REACT_APP_FILEURL + removePublicPrefixURL(clientService.contract.url), '_blank')
    }

    const isAdmin = user.userType === "USER_TYPE_ADMIN";
    const isStateRequested = clientService.state === "DRINKEREEZ_SERVICE_STATE_REQUESTED";

    return (
        <div>
            {
                isAdmin && <ShowClientDetails client={clientService.client} />
            }
            <FormLineParagHeader label="title.serviceRequest"/>
            <div className='row'>
                <FormLineSelect col={4} list={resources.drinkereezServices} name="type" label="label.serviceType" value={clientService.type} readOnly={true}/>
                <FormLineInput col={4} label="label.contract" name="name" value={clientService.contract.name} readOnly={true} handleClick={openContract}/>
                <FormLineSelect col={4} list={resources.drinkereezServiceStates} name="type" label="label.serviceType" value={clientService.state} readOnly={true}/>
            </div>
            <FormLineTextArea name="motivation" rows={5} label="label.motivation" value={clientService.motivation} readOnly={true}/>
            <FormLineTextArea name="comment" rows={5} label="label.comment" value={comment} onChange={changeHandler} readOnly={!isAdmin || !isStateRequested}/>
            <FormLineErrorMessage message={error.errorMessage} errorJSON={error.errorJSON}/>
            {
                isAdmin && isStateRequested && <FormButton onClick={() => updateState(true)} label='button.accept'/>
            }
            {    
                isAdmin && isStateRequested && <FormButton onClick={() => updateState(false)} label='button.deny'/>
            }
            <FormButton onClick={closeScreen} level="secondary" label='button.cancel'/>
        </div>
    )
}