import {useCallBackend} from '../General/CallBackend';
import {log} from '../../Log';
import orderTest from '../../resources/test/orderTest.json';

export function CallgetAllOrders (login,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';
    log.debug("send JSON getAllOrders");

    if (testMode) {
        //responseFunc ({err : "error.server_return_error"});
        responseFunc (orderTest.orders);
    }
    else {
        const body = { 
            req : {...login,action: 8}
        }

        log.debug(body);
        log.debug("post");

        CallBackend ("product",body, response => {
            responseFunc(response);
        });
    }
}