import React, {useState,useContext,useEffect} from 'react';
import resources from '../../resources/lists/dropdownlist.json';
import { AppContext } from '../Drinkereez';
import {log} from '../../Log';
import {CallSaveTarifNationalCode} from './CallNationalCode'
import FormLineInput from '../layout/forms/FormLineInput';
import FormLineSelect from '../layout/forms/FormLineSelect';
import FormLineErrorMessage from '../layout/forms/FormLineErrorMessage';
import FormButton from '../layout/forms/FormButton';
//import FormAddRemoveList from '../layout/forms/FormAddRemoveList';
import { transformRecords } from '../GeneralFunc';
import FormLineCheckBoxGroup from '../layout/forms/FormLineCheckBoxGroup';
import PropTypes from 'prop-types';

EditTarifNationalCode.propTypes = {
    tarif: PropTypes.any, // Replace 'any' with the specific type you expect
    setTarif: PropTypes.any, // Replace 'any' with the specific type you expect
    stopShowTarif: PropTypes.any, // Replace 'any' with the specific type you expect
    tarifSaved: PropTypes.any, // Replace 'any' with the specific type you expect
    editMode: PropTypes.any, // Replace 'any' with the specific type you expect
    setEditMode: PropTypes.any, // Replace 'any' with the specific type you expect
    natCodes: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function EditTarifNationalCode(props) {
    const {login} = useContext(AppContext);
    const {tarif,setTarif,stopShowTarif,tarifSaved,editMode,setEditMode,natCodes} = props;
    const [error, setError] = useState({errorMessage: '',errorJSON: {}});
    const [selectedNatCodes, setSelectedNatCodes] = useState(tarif.natCodes);
    const [forceRender, setForceRender] = useState(false); // eslint-disable-line no-unused-vars
    log.debug("in EditTarifNationalCode");

    useEffect(() => { 
        log.debug("EditTarifNationalCode updated");
        setForceRender(prevValue => !prevValue); 
    },[editMode,selectedNatCodes]);

    function changeHandler (e) {
        setTarif({...tarif,[e.name]: e.value});
    }

    function cancelHandler () {
        log.debug("in EditTarifNationalCode - cancelHandler");
        stopShowTarif();
    }

    function editHandler () {
        log.debug("in EditTarifNationalCode - editHandler");
        setEditMode(true);
    }

    function saveHandler () {
        log.debug("EditTarifNationalCode - saveHandler");
        log.debug(selectedNatCodes);

        const saveTarif = {...tarif, natCodes: selectedNatCodes,}

        setTarif(saveTarif);

        log.debug(saveTarif);

        CallSaveTarifNationalCode(login,saveTarif,response => {
            if (response.err || response.ret?.err) {
                log.debug("error");
                setError({errorMessage: response.err + ' ' + response.ret?.err, errorJSON: {login,tarif}});
                return;
            }
            log.debug(response);
            tarifSaved(response);           
        });
    }

    /*function getNatCodes () {
        if (!selectedNatCodes) return;
        return makeList(createLinkedList(selectedNatCodes,natCodes,"natCode"),selectedNatCodes.length);
    }*/

    return (
        <div className='editTarifNationalCode'>
            <div className='row'>
                <FormLineSelect col="3" list={resources.calculatorCountries} name="country" label="label.country" readOnly={true} value={tarif.country} onChange={changeHandler} required={true}/>
                <FormLineSelect col="3" list={resources.tarifTypes} name="tarifType" label="label.tarifType" readOnly={!editMode} value={tarif.tarifType} onChange={changeHandler} required={true}/>
                <FormLineSelect col="3" list={resources.calculationBases} name="calculationBase" label="label.calculationBase" readOnly={!editMode} value={tarif.calculationBase} onChange={changeHandler} required={true}/>
                <FormLineInput col="3" maxLength={10} type="text" readOnly={!editMode} label="label.value" name="value" value={tarif.value} onChange={changeHandler} required={true}/>
            </div> 
            <FormLineCheckBoxGroup label="label.linkedNatCodes" list={transformRecords(natCodes,"natCode")} checkedList={selectedNatCodes} setCheckedList={setSelectedNatCodes} readOnly={!editMode}/>
            <FormLineErrorMessage message={error.errorMessage} errorJSON={error.errorJSON}/>
            {
                !editMode && <FormButton onClick={editHandler} label='button.edit'/>
            }
            {
                editMode && <FormButton onClick={saveHandler} label='button.save'/>
            }
            <FormButton onClick={cancelHandler} level='secondary' label='button.cancel'/>
        </div>
    )
}


/*
            <div className='row'>
                <FormLineInput type="text" readOnly={true} label="label.linkedNatCodes" name="value" value={getNatCodes()}/>
            </div> 
            <div className='row'>
            {
                editMode && <FormAddRemoveList totalList={transformRecords(natCodes,"natCode")} selectedList={selectedNatCodes} setSelectedList={setSelectedNatCodes}/>
            }            
            </div> 
*/