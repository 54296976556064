import React from 'react'
import FormLineInput from '../layout/forms/FormLineInput';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
import PropTypes from 'prop-types';

CompanyGeneralData.propTypes = {
    companyCommercialName: PropTypes.any, // Replace 'any' with the specific type you expect
    companyName: PropTypes.any, // Replace 'any' with the specific type you expect
    companyId: PropTypes.any, // Replace 'any' with the specific type you expect
    VATNumber: PropTypes.any, // Replace 'any' with the specific type you expect
    exciseNumber: PropTypes.any, // Replace 'any' with the specific type you expect
    header: PropTypes.any, // Replace 'any' with the specific type you expect
    updateCompanyGeneralData: PropTypes.any, // Replace 'any' with the specific type you expect
    exciseCompany: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function CompanyGeneralData(props) {
    const {companyCommercialName,name,companyId,VATNumber,exciseNumber,header,updateCompanyGeneralData,exciseCompany} = props;

    function changeHandler (e) {
        updateCompanyGeneralData({[e.name]: e.value});
    }

    function extraExciseCompany () {
        return (            
        <div className='row'>
            <FormLineInput col={6} type="text" label="label.companyCommercialName" name="companyCommercialName" value={companyCommercialName} onChange={changeHandler} required={true}/>
            <FormLineInput col={3} type="text" label="label.exciseNumber" name="exciseNumber" value={exciseNumber} onChange={changeHandler} required={true}/>
        </div>
        )
    }

    return (
        <div className="CompanyData mb-5">
            <FormLineParagHeader label={header}/>
            <div className='row'>
                <FormLineInput col={6} type="text" label="label.companyName" name="name" value={name} onChange={changeHandler} required={true}/>
                <FormLineInput col={3} type="text" label="label.VATNumber" name="VATNumber" value={VATNumber} onChange={changeHandler} required={true}/>
                <FormLineInput col={3} type="text" label="label.companyId" name="companyId" value={companyId} onChange={changeHandler} required={true}/>
            </div>
            {
                exciseCompany && extraExciseCompany()
            }
        </div>
    )
}
