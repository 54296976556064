import {useCallBackend} from '../General/CallBackend';
import {log} from '../../Log';
import xmlTest from '../../resources/test/xmlTest.json';
import exciseTest from '../../resources/test/exciseTest.json';

export function CallUploadXML (login,xmlData,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';
    log.debug("send JSON upload XML");

    const body = { 
        req : {...login,action: 9},
        ...xmlData
    }

    log.debug(body);
    log.debug("post");

    if (testMode) {
        log.debug("test")
    }
    else {
        CallBackend ("product",body, response => {
            responseFunc(response);
        });
    }
}

export function CallGetAllXml (login,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';
    log.debug("send JSON upload XML");

    const body = { 
        req : {...login,action: 11}
    }

    log.debug(body);
    log.debug("post");

    if (testMode) {
        responseFunc (xmlTest.xmlDatas);
    }
    else {
        CallBackend ("product",body, response => {
            responseFunc(response);
        });
    }
}

export function CallParseXML (login,id,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';
    log.debug("send JSON parse XML");

    const body = { 
        req : {...login,action: 10,id:id}
    }

    log.debug(body);
    log.debug("post");

    if (testMode) {
        responseFunc (exciseTest.ie801);
    }
    else {
        CallBackend ("product",body, response => {
            responseFunc(response);
        });
    }
}