import React, {useContext,useEffect,useState} from 'react'
import { useTranslation } from "react-i18next";
import { AppContext } from '../Drinkereez';
import {log} from '../../Log';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
import TableLayout from '../layout/tables/TableLayout';
import TableRowCell from '../layout/tables/TableRowCell';
import FormButton from '../layout/forms/FormButton';
import resources from '../../resources/lists/dropdownlist.json'
import FormLineSelect from '../layout/forms/FormLineSelect';
import FormLineInput from '../layout/forms/FormLineInput';
import {CallgetNationalCodes} from './CallConverter';
import { CallAdminCalculator } from './CallCalculator';
//import {CallgetTarifForNationalCode} from '../NationalCode/CallNationalCode'
import { orderByField,toNumber,GetLabelFromList } from '../GeneralFunc';

export default function Converter() {
    const {t} = useTranslation();
    const {login} = useContext(AppContext);
    const [searchOptions,setSearchOptions] = useState ({country:"BE",productType:"",sparklingType:"",packageType:"",volume:"",abv:"",
                                                        plato:"",productionHL:"",cnCode:"",epc:"",netPrice:"",totalPrice:"",commision:""});
    const [searchResults,setSearchResults] = useState();
    //const [tarifs,setTarifs] = useState ();
    const [result,setResult] = useState ();
    const [forceRender, setForceRender] = useState(false); // eslint-disable-line no-unused-vars
    const columnsNatCode = ["label.natcode","label.description","label.cnCode","label.epc"];
    const columnsTarif = ["label.tarifType","label.calculationBase","label.value","label.result"];

    useEffect(() => { 
        log.debug("Converter updated");
        setForceRender(prevValue => !prevValue);
    },[searchOptions,searchResults,result]);

    function onClickTable (e) {
        calculate(e.target.id);
    }

    function getNationalCodes() {
        log.debug("Converter - getAllNationalCodes");
        const abvT = searchOptions.abv === "" ? "0" : searchOptions.abv;
        const proT = searchOptions.productionHL === "" ? "0" : searchOptions.productionHL;
        const searchOpt = {country:searchOptions.country,productType:searchOptions.productType,sparklingType:searchOptions.sparklingType,
                            packageType:searchOptions.packageType,abv:abvT,productionHL:proT,
                            cnCode:searchOptions.cnCode,epc:searchOptions.epc}
        CallgetNationalCodes(login,searchOpt,response => {
            if (response.err || response.ret?.err) {
                log.debug("error");
                return;
            }
            log.debug("response")
            log.debug(response);
            setSearchResults(response.natCodes);
            if (response.natCodes && response.natCodes.length===1)
                calculate(response.natCodes[0].natCode.id); 
        });
    } 

    function calculate(id) {
        const input = { natCodeId:id,
                        degrees:toNumber(searchOptions.abv),
                        plato:toNumber(searchOptions.plato),
                        volume:toNumber(searchOptions.volume),
                        netPrice:toNumber(searchOptions.netPrice),
                        commision:toNumber(searchOptions.commision)
                    };
        CallAdminCalculator(login,input,response => {
            if (response.err || response.ret?.err) {
                log.debug("error");
                return;
            }
            log.debug("response")
            log.debug(response);
            setResult(response);
        });
    }

    /*function getTarifs(id) {
        log.debug("Converter - getTarifs");
        CallgetTarifForNationalCode(login,id,response => {
            if (response.err || response.ret?.err) {
                log.debug("error");
                return;
            }
            log.debug("response")
            log.debug(response);
            setTarifs(response.tarifNatCodes);
        });
    }*/

    function clearHandler () {
        log.debug("in Converter - clearHandler");
        setSearchOptions({country:"BE",productType:"",sparklingType:"",packageType:"",volume:"",abv:"",
                          plato:"",productionHL:"",cnCode:"",epc:"",netPrice:"",totalPrice:"",commision:""});
    }

    function searchHandler () {
        log.debug("in Converter - searchHandler");
        setResult({netPrice:"",commision:"",VAT:"",duty:"",total:"",tarifs:[]});
        getNationalCodes();
    }

    function changeSearchHandler (e) {
        setSearchOptions({...searchOptions,[e.name]: e.value});
    }

    function getNatCodeDescription (natCode) {
        var descr="";
        if (natCode.productType) descr = descr + t(GetLabelFromList(resources.generalProductTypes,natCode.productType));
        if (natCode.sparklingType && natCode.sparklingType!=="SPARKLING_TYPE_NOT_APPLICABLE") descr = descr + ", " + t(GetLabelFromList(resources.sparklingTypes,natCode.sparklingType));
        if (natCode.packageType) descr = descr + ", " + t(GetLabelFromList(resources.packageTypes,natCode.packageType));
        if (natCode.abvFrom && natCode.abvFrom!==-1) {
            descr = descr + ", " + natCode.abvFrom + "% " + t("label.till");
            if (natCode.abvTo && natCode.abvTo!==-1) descr = descr + " " + natCode.abvTo + "%";
            else descr = descr + " ...";
        }
        if (natCode.productionHLFrom!==-1) {
            descr = descr + ", " + natCode.productionHLFrom + "hl " + t("label.till");
            if (natCode.productionHLTo && natCode.productionHLTo!==-1) descr = descr + " " + natCode.productionHLTo + "hl";
            else descr = descr + " ...";
        }
        return descr;
    }

    function displayDataNatCode () {
        if (!searchResults) return('');
        
        const transformedRecords = searchResults.map((record) => ({
            id: record.natCode.id,
            natCode: record.natCode.natCode,
            description: getNatCodeDescription(record.natCode),
            cnCode: record.natCodeEUCode.cnCode,
            epc: t(GetLabelFromList(resources.EPC, record.natCodeEUCode.epc)),
          }));

        const orderedList = orderByField(transformedRecords,"natCode");

        const ret = orderedList.map((record) => {
            return (
                <tr key={record.id}>
                    <TableRowCell first="true" value={record.natCode} onClick={onClickTable} id={record.id}/>
                    <TableRowCell value={record.description}/>
                    <TableRowCell value={record.cnCode}/>
                    <TableRowCell value={record.epc}/>
                </tr>
            )
        }); 
        return (ret);
    }

    function displayDataTarif () {
        if (!result || !result.tarifs) return('');
        const ret = result.tarifs.map((tar) => {
            return (
                <tr key={tar.id}>
                    <TableRowCell first="true" label={GetLabelFromList(resources.tarifTypes,tar.tarifType)} id={tar.id}/>
                    <TableRowCell label={GetLabelFromList(resources.calculationBases,tar.calculationBase)} id={tar.id}/>
                    <TableRowCell value={tar.value ? tar.value : "0"}/>
                    <TableRowCell value={tar.result ? tar.result : "0"}/>
                </tr>
            )
        }) 
        return (ret);
    }

    function showReturn() {
        if (!result) return ("");
        return (
            <div className='row'>
                <FormLineInput col="2" type="text" label="label.netPrice" value={result.netPrice} readOnly={true}/>
                <FormLineInput col="2" type="text" label="label.duty" value={result.duty} readOnly={true}/>
                <FormLineInput col="2" type="text" label="label.commision" value={result.commision} readOnly={true}/>
                <FormLineInput col="2" type="text" label="label.VAT" value={result.VAT} readOnly={true}/>
                <FormLineInput col="2" type="text" label="label.totalPrice" value={result.total} readOnly={true}/>
            </div>
        )
    }

    return (
        <div className="Converter">
            <div className='row'>
                <FormLineParagHeader col='3' label={"title.converter"}/>
            </div>
            <div className='row'>
                <FormLineSelect col="2" list={resources.calculatorCountries} name="country" label="label.country" value={searchOptions.country} onChange={changeSearchHandler} readOnly={true}/>
                <FormLineSelect col="2" list={resources.generalProductTypes} name="productType" label="label.productType" value={searchOptions.productType} onChange={changeSearchHandler}/>
                <FormLineSelect col="2" list={resources.sparklingTypes} name="sparklingType" label="label.sparklingType" value={searchOptions.sparklingType} onChange={changeSearchHandler}/>
                <FormLineSelect col="2" list={resources.packageTypes} name="packageType" label="label.packageType" value={searchOptions.packageType} onChange={changeSearchHandler}/>
                <FormLineInput col="2" maxLength={20} type="text" label="label.cnCode" name="cnCode" value={searchOptions.cnCode} onChange={changeSearchHandler}/>
                <FormLineSelect col="2" list={resources.EPC} name="epc" label="label.epc" value={searchOptions.epc} onChange={changeSearchHandler}/>
            </div>
            <div className='row'>
                <FormLineInput col="2" maxLength={20} type="text" label="label.volumeL" name="volume" value={searchOptions.volume} onChange={changeSearchHandler}/>
                <FormLineInput col="2" maxLength={20} type="text" label="label.degrees" name="abv" value={searchOptions.abv} onChange={changeSearchHandler}/>
                <FormLineInput col="2" maxLength={20} type="text" label="label.plato" name="plato" value={searchOptions.plato} onChange={changeSearchHandler}/>
                <FormLineInput col="2" maxLength={20} type="number" label="label.annualProductionHL" name="productionHL" value={searchOptions.productionHL} onChange={changeSearchHandler}/>
            </div>            
            <div className='row'>
                <FormLineInput col="2" maxLength={20} type="text" label="label.netPrice" name="netPrice" value={searchOptions.netPrice} onChange={changeSearchHandler}/>
                <FormLineInput col="2" maxLength={20} type="text" label="label.commision" name="commision" value={searchOptions.commision} onChange={changeSearchHandler}/>
            </div>
            <div className='row'>
                <div className='col-md-2'>
                    <FormButton onClick={searchHandler} label='button.search'/>
                </div>
                <div className='col-md-2'>
                    <FormButton onClick={clearHandler} label='button.reset'/>
                </div>
            </div>
            <TableLayout columns={columnsNatCode} displayData={displayDataNatCode}/>
            <TableLayout columns={columnsTarif} displayData={displayDataTarif}/>
            {
                showReturn()
            }
        </div>   
    )
}

//                <FormLineInput col="2" maxLength={20} type="text" label="label.totalPrice" name="totalPrice" value={searchOptions.totalPrice} onChange={changeSearchHandler}/>
