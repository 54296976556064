import React, {useState} from 'react'
import Address from '../General/Address'
import ContactPerson from '../General/ContactPerson'
import CompanyGeneralData from '../General/CompanyGeneralData'
import NewPassword from '../General/NewPassword'
import FormLineErrorMessage from '../layout/forms/FormLineErrorMessage'
import { validEmail, validPassword } from '../Regex';
import { CallRegistrateClient }  from './CallClient';
import FormButton from '../layout/forms/FormButton'
import PropTypes from 'prop-types';

RegisterClient.propTypes = {
    producerRegistered: PropTypes.func.isRequired
};

export default function RegisterClient(props) {
    const {producerRegistered} = props;
    const [contactPerson,setContactPerson] = useState ({});
    const [company,setCompany] = useState ({});
    const [newPasswords,setNewPasswords] = useState ({});
    const [error, setError] = useState({errorMessage: '',errorJSON: {}});

    function submitHandler (e) {
        setError({errorMessage: '',errorJSON: {}});
        e.preventDefault();
        if (!validEmail.test(contactPerson.eMail)) {
            setError({errorMessage: 'incorrect_regex_email',errorJSON: {}});
        //} else if (!validPassword.test(newPasswords.newPassword)) {
        //    setError({errorMessage: 'incorrect_regex_password',errorJSON: {}});
        } else if (newPasswords.newPassword !== newPasswords.confirmedPassword) {
            setError({errorMessage: 'error.password_not_equal',errorJSON: {}});
        } else {
            CallRegistrateClient(contactPerson,company,newPasswords,response => {
                if (response.err) {
                    setError({errorMessage: response.err,errorJSON: {contactPerson,company,newPasswords}});
                } else {
                    producerRegistered();
                }
            });
        }
    }

    function resetHandler () {
        setContactPerson({});
        setCompany({});
        setNewPasswords({});
        setError({errorMessage: '',errorJSON: {}});
    }

    function updateContactPerson (change) {
        setContactPerson({...contactPerson,...change});
    }

    function updateAddress (change) {
        setCompany({...company,mainAddress:{...company.mainAddress,...change}});
    }

    function updateCompanyGeneralData (change) {
        setCompany({...company,...change});
    }

    function updateNewPassword (change) {
        setNewPasswords({...newPasswords,...change});
    }

    return (
        <form>
            <CompanyGeneralData {...company} header="title.company" updateCompanyGeneralData={updateCompanyGeneralData}/>
            <Address {...company.mainAddress} header="title.companyAddress" updateAddress={updateAddress}/>
            <ContactPerson {...contactPerson} header="title.contactPerson" updateContactPerson={updateContactPerson}/>
            <NewPassword {...newPasswords} header="title.password" updatePassword={updateNewPassword}  askOldPassword={false}/>
            <FormLineErrorMessage message={error.errorMessage} errorJSON={error.errorJSON}/>
            <FormButton type="submit" onClick={submitHandler} label='button.submit'/>
            <FormButton type="reset" onClick={resetHandler} label='button.reset' level='secondary'/>
        </form> 
    )
}