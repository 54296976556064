import React from 'react';
import { useTranslation } from "react-i18next";
import { validNumber,validDecNumber,validOnlyLetters } from '../../Regex';
//import { isValidPhoneNumber, formatPhoneNumberIntl } from "react-phone-number-input";
import PropTypes from 'prop-types';

FormLineInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    type: PropTypes.oneOf(['input','text', 'number', 'email', 'password', 'decNumber', 'onlyLetters', 'phoneNumber']),
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    col: PropTypes.number,
    handleClick: PropTypes.func
};

FormLineInput.defaultProps = {
    type: 'text',
    required: false,
    readOnly: false,
    maxLength: 50,
    placeholder: 'value',
    className: 'my-2',
    col: 12
};

export default function FormLineInput(props) {
    const { t } = useTranslation();
    const { name, value, type, label, required, readOnly, maxLength, placeholder, className, onChange, ref, col, donotShowEmpty,handleClick } = props;

    function changeHandler(e) {
        const { value: inputValue } = e.target;
        if ((type === 'number' && !validNumber.test(inputValue)) ||
            (type === 'decNumber' && !validDecNumber.test(inputValue)) ||
            (type === 'onlyLetters' && !validOnlyLetters.test(inputValue))) {
            return;
        }
        onChange({ name, value: inputValue });
    }

    function getEffectiveType() {
        return ['number', 'decNumber', 'onlyLetters', 'phoneNumber'].includes(type) ? 'text' : type;
    }

    if (donotShowEmpty && !value && readOnly) {
        return null;
    }

    const inputType = getEffectiveType();
    const inputClass = `form-floating ${className} col-md-${col}`;

    return (
        <div className={inputClass}>
            <input
                ref={ref}
                className="form-control border-0 bottom-border-only"
                id="floatingInput"
                type={inputType}
                readOnly={readOnly}
                name={name}
                value={value}
                onChange={changeHandler}
                required={required}
                placeholder={placeholder}
                maxLength={maxLength}
                onClick={handleClick && handleClick}
            />
            <label className="mx-1" htmlFor="floatingInput">{t(label)}</label>
        </div>
    );
}

/*
export default function FormLineInput (props) {
    const {t} = useTranslation();
    const {name,value,type,label,required,readOnly,maxLength,placeholder,className,onChange,ref,donotShowEmpty} = props;
    const maxLen = maxLength ? maxLength : 50;
    const placeH = placeholder ? placeholder : 'value';
    const extraClassName = className ? className : 'my-2';
    const col = props.col ? props.col : 12;
    const classN = "form-floating " + extraClassName + " col-md-" + col;

    function changeHandler (e) {
        if (type==='number' && !validNumber.test(e.target.value)) return;
        if (type==='decNumber' && !validDecNumber.test(e.target.value)) return;
        if (type==='onlyLetters' && !validOnlyLetters.test(e.target.value)) return;
        /*if (type==='phoneNumber') {
            const isValid = isValidPhoneNumber(e.target.value);
            const formatedPhoneNumber = formatPhoneNumberIntl(e.target.value);
            console.log("e.target.value " + e.target.value + " phone : " + formatedPhoneNumber);
            if (isValid) {
                onChange({name: name, value: formatedPhoneNumber});
                return;
            }
        }*//*
        onChange({name: name, value: e.target.value});    
    }

    function getType () {
        if (type==='number' || type==='decNumber' || type==='onlyLetters' || type==='phoneNumber') return 'text'
        return type;
    }

    if (donotShowEmpty && !value && readOnly) {
        return (<></>);
    }

    return (
        <div className={classN}>
            <input ref={ref} className="form-control border-0 bottom-border-only" id="floatingInput" type={getType()} readOnly={readOnly} name="value" value={value} onChange={changeHandler} required={required} placeholder={placeH} maxLength={maxLen}/>
            <label className="mx-1" htmlFor="floatingInput">{t(label)}</label>
        </div>
    ); 
}*/