import React, {useContext} from 'react';
import { AppContext } from '../Drinkereez';
import FormLineInput from '../layout/forms/FormLineInput';
import FormLineSelect from '../layout/forms/FormLineSelect';
import FormButton from '../layout/forms/FormButton';
import resources from '../../resources/lists/dropdownlist.json';
import { CallParseXML } from './CallXml';
import {log} from '../../Log'; 
import PropTypes from 'prop-types';

ConsultXML.propTypes = {
    xmlData: PropTypes.any, // Replace 'any' with the specific type you expect
    stopShowXml: PropTypes.any, // Replace 'any' with the specific type you expect
    showDoc: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function ConsultXML(props) {
    const {xmlData,stopShowXml,showDoc} = props;
    const {login} = useContext(AppContext);

    function parseXML () {
        log.debug("ConsultXML - parseXML");
        CallParseXML(login,xmlData.id,response => {
            log.debug(response);
            if (response.err || response.ret?.err) {
                log.debug("error");
                return;
            }
            showDoc(response);
        });
    }

    return (
        <form>
            <div className='row'>
                <FormLineInput col='5' type="input" label="label.name" name="name" value={xmlData.name} readOnly="true"/>
            </div>
            <div className='row'>
                <FormLineSelect col='5' type="input" list={resources.ExciseXMLTypes} label="label.type" name="type" value={xmlData.type} readOnly="true"/>
            </div>
            <div className='row'>
                <FormLineInput col='5' type="input" label="label.xmlUploadDate" name="type" value={xmlData.uploadDate} readOnly="true"/>
            </div>
            <div className='row'>
                <FormLineSelect col='5' type="input" list={resources.XMLStates} label="label.state" name="type" value={xmlData.state} readOnly="true"/>
            </div>
            <div className='row'>
                {xmlData.xml} 
            </div>
            {
                xmlData.state==="XML_STATES_UPLOADED" && <FormButton type="submit" onClick={parseXML} label='button.parse' level='primary'/>
            }
            <FormButton type="cancel" onClick={stopShowXml} label='button.cancel' level='secondary'/>
        </form> 
    )
}