import {useCallBackend} from '../General/CallBackend';
import {log} from '../../Log';
//import shopTest from '../../resources/test/shopTest.json';

export function CallSendProducer (id,login,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';
    log.debug("send JSON CallSendProducer");

    const body = {req : {...login,action: 0,id: id}}

    log.debug(body);
    log.debug("post");

    if (testMode) {
        log.debug("test")
    } else {
        
        CallBackend ("shop", body, response => {
            responseFunc(response);
        });
    }
}

export function CallSendProduct (id,login,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';
    log.debug("send JSON CallSendProduct");

    const body = {req : {...login,action: 10,id: id}}

    log.debug(body);
    log.debug("post");

    if (testMode) {
        log.debug("test")
    } else {
        
        CallBackend ("shop", body, response => {
            responseFunc(response);
        });
    }
}