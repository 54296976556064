import React, {useState,useContext} from 'react';
import { AppContext } from '../Drinkereez';
import {log} from '../../Log';
import FormLineFileInput from '../layout/forms/FormLineFileInput';
import FormLineInput from '../layout/forms/FormLineInput';
import FormButton from '../layout/forms/FormButton';
import { CallUploadFile } from './CallUploadFile'

export default function TestUploadFile () {
    const [subdirectory, setSubdirectory] = useState();
    const [file, setFile] = useState();
    const {login} = useContext(AppContext);

    function changeHandler (e) {
        setSubdirectory(e.value);
    }

    function saveFile () {
        log.debug("save file");
        log.debug("in directory " + subdirectory);
        CallUploadFile(login,file,subdirectory,response => {
            if (response.err) {
                log.debug("error : " + response.err);
            } else {
                log.debug("ok" + response);
            }
        });
    }

    return (
        <div className="TestUploadFile">
            <FormLineInput maxLength={20} type="text" label="subdirectory" name="subdirectory" value={subdirectory} onChange={changeHandler} required={true}/>
            <FormLineFileInput label="test" subdirectory={subdirectory} file={file} setFile={setFile}/>
            <FormButton className="col-sm-1" onClick={saveFile} label='button.save'/>
        </div>
    )
}