import {useCallBackend} from '../General/CallBackend';
import {log} from '../../Log';
import converterTest from '../../resources/test/converterTest.json';

export function CallgetNationalCodes (login,searchOptions,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';
    log.debug("send JSON CallgetNationalCodes");

    const body = { 
        req : {...login,action: 10},
        ...searchOptions
    }

    log.debug(body);
    log.debug("post");

    if (testMode) {
        //responseFunc ({err : "error.server_return_error"});
        responseFunc (converterTest.natCodes);
    }
    else {

        CallBackend ("NationalCode",body, response => {
            responseFunc(response);
        });
    }
}