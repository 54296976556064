import React from 'react';
import PropTypes from 'prop-types';
import FormLineInput from '../layout/forms/FormLineInput';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';

ShowClientDetails.propTypes = {
    client: PropTypes.any
};

export default function ShowClientDetails({client}) {
    const nameContact = client.mainContact.person.firstName + " " + client.mainContact.person.lastName

    return (
        <div>
            <FormLineParagHeader label="title.client"/>
            <div className='row'>
                <FormLineInput col={4} label="label.companyName" value={client.company.name} readOnly={true}/>
                <FormLineInput col={4} label="label.VATNumber" value={client.company.VATNumber} readOnly={true}/>
                <FormLineInput col={4} label="label.exciseNumber" value={client.exciseNumber} readOnly={true}/>
            </div>
            <div className='row'>
                <FormLineInput col={4} label="label.contactName" value={nameContact} readOnly={true}/>
                <FormLineInput col={4} label="label.eMail" value={client.mainContact.person.eMail} readOnly={true}/>
                <FormLineInput col={4} label="label.phoneNumber" value={client.mainContact.person.phoneNumber} readOnly={true}/>
            </div>
        </div>
    )
}