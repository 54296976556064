import React, {useContext,useEffect,useState} from 'react'
import { AppContext } from '../Drinkereez';
import {log} from '../../Log';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
//import { useTranslation } from "react-i18next";
import TableRowCell from '../layout/tables/TableRowCell';
import { CallgetAllProducers } from './CallProducer';
import TableLayout from '../layout/tables/TableLayout';
import PropTypes from 'prop-types';

ShowProducers.propTypes = {
    producerSelected: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function ShowProducers(props) {
    const {producerSelected} = props;
//    const {t} = useTranslation();
    const {login} = useContext(AppContext);
    const columns = ["label.companyName","label.VATNumber","label.exciseNumber","label.country"];
    const [producers,setProducers] = useState ();

    useEffect(() => {
        getAllProducers();
      }, []);

    function getAllProducers() {
        log.debug("ShowProducers - getAllProducers");
        CallgetAllProducers(login,response => {
            if (response.err || response.ret?.err) {
                log.debug("error");
                return;
            }
            log.debug(response);
            setProducers(response.producers);
        });
    }  

    function onClickTable (e) {
        log.debug("clicked : " + e.currentTarget.id);
        producerSelected(producers.find(({id}) => id === e.currentTarget.id));
    }

    function displayData () {
        if (!producers) return('');
        const ret = producers.map((producer) => {
            return (
                <tr key={producer.id}>
                    <TableRowCell first="true" value={producer.company?.name} onClick={onClickTable} id={producer.id}/>
                    <TableRowCell value={producer.company?.VATNumber}/>
                    <TableRowCell value={producer.exciseNumber}/>
                    <TableRowCell value={producer.country}/>
                </tr>
            )
        }) 
        return (ret);
    }

    return (
        <div className="ShowProducers">
            <div className='row'>
                <FormLineParagHeader col='3' label={"title.producers"}/>
            </div>
            <TableLayout columns={columns} displayData={displayData}/>
        </div>   
    )
}