import React, {useState,useContext} from 'react'
import { AppContext } from '../Drinkereez';
import { CallUpdateUser,CallChangePassword } from './CallUser'
import NewPassword from '../General/NewPassword';
import ContactPerson from '../General/ContactPerson'
import FormLineErrorMessage from '../layout/forms/FormLineErrorMessage';
import {log} from '../../Log'; 
import FormButton from '../layout/forms/FormButton';

export default function UserData() {
    const {user,setUser,login,setLogin} = useContext(AppContext);
    const [editMode,setEditMode] = useState(false);
    const [changePasswordMode,setChangePasswordMode] = useState(false);
    const [error, setError] = useState({errorMessage: '',errorJSON: {}});
    const [changedPerson,setChangedPerson] = useState(user.person);
    const [newPwd, setNewPwd] = useState(); 

    //log.debug("userData");
    //log.debug({user:user});
    //log.debug({changedPerson:changedPerson});

    function saveHandler () {
        if (editMode) {
            CallUpdateUser(user.id,changedPerson,login,response => {
                if (response.err) {
                    setError({errorMessage : response.err, errorJSON: {action: 'update user', changedPerson}});
                } else {
                    setError({errorMessage : '', errorJSON: {}});
                    setUser({...user,person: changedPerson});
                }
            })
        } else if (changePasswordMode) {
            CallChangePassword(user.id,newPwd,login,response => {
                if (response.err) {
                    setError({errorMessage : response.err, errorJSON: {action: 'new password', user}});
                } else {
                    setError({errorMessage : '', errorJSON: {}});
                    setLogin({...login,pwd:newPwd});
                }
            })
        }
    }

    function cancelHandler () {
        setEditMode(false);
        setChangePasswordMode(false);
    }

    function editHandler () {
        setEditMode(true);
        setChangePasswordMode(false);
    }

    function changePasswordHandler () {
        setEditMode(false);
        setChangePasswordMode(true);
    }

    function updateNewPassword (ret) {
        if (ret.newPassword) setNewPwd(ret.newPassword);
    }

    function updateContactPerson (change) {
        setChangedPerson({...changedPerson,...change});
    }

    return (
        <div className='userData'>
        <ContactPerson header="title.personalData" updateContactPerson={updateContactPerson} readOnly={!editMode} {...changedPerson}/>
        {
            changePasswordMode && <NewPassword header="title.password" updatePassword={updateNewPassword}  askOldPassword="true"/>
        }
        <FormLineErrorMessage message={error.errorMessage} errorJSON={error.errorJSON}/>
        {
            (editMode || changePasswordMode) && 
            <>
                <FormButton type="submit" onClick={saveHandler} label='button.save'/>
                <FormButton type="submit" onClick={cancelHandler} level='secondary' label='button.cancel'/>
            </>
        }
        {
            (!editMode && !changePasswordMode) &&
            <> 
                <FormButton type="submit" onClick={editHandler} label='button.edit'/>
                <FormButton type="submit" onClick={changePasswordHandler} level='secondary' label='button.changePassword'/>
            </>
        }
    </div> 
    )
}
