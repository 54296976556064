import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import LoginNavBar from './LoginNavBar';
import LoginScreen from './User/LoginScreen';
import RegisterClient from './Client/RegisterClient';
import ActivateUser from './User/ActivateUser';
import AdminPortal from './Administrator/AdminPortal'
import Title from './Title';
import UserPortal from './User/UserPortal';
import { log } from '../Log';
import Footer from './Footer';
import AdminPortalNavBar from './Administrator/AdminPortalNavBar';
import ClientPortalNavBar from './Client/ClientPortalNavBar';
import ReceiverB2BNavBar from './ReceiverB2B/ReceiverB2BNavBar';
import ReceiverB2BPortal from './ReceiverB2B/ReceiverB2BPortal';
import ProtectedRoute from './ProtectedRoute'; // Import the ProtectedRoute component

export const AppContext = React.createContext();

export default function Drinkereez() {
    const [user,setUser] = useState();
    const [login,setLogin] = useState();
    const [producer,setProducer] = useState();
    const [client,setClient] = useState();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        log.debug("in Drinkereez");
        log.debug({login:login});
        log.debug({user:user});
        log.debug({producer:producer});
        log.debug({client:client});

        // Retrieve cookies on component mount
        const userFromCookie = Cookies.get('user');
        const loginFromCookie = Cookies.get('login');
        const clientFromCookie = Cookies.get('client');

        if (userFromCookie) setUser(JSON.parse(userFromCookie));
        if (loginFromCookie) setLogin(JSON.parse(loginFromCookie));
        if (clientFromCookie) setClient(JSON.parse(clientFromCookie));
    }, []);

    // Update cookies whenever user, login, or client state changes
    useEffect(() => {
        if (user) {
            Cookies.set('user', JSON.stringify(user), { expires: 7 });
            if (user.userType === 'USER_TYPE_ADMIN') {
                navigate('/admin-portal/home');
            } else {
                navigate('/portal/home');
            }
        } else {
            Cookies.remove('user');
        }
    }, [user]);

    useEffect(() => {
        if (login) {
            Cookies.set('login', JSON.stringify(login), { expires: 7 });
        } else {
            Cookies.remove('login');
        }
    }, [login]);

    useEffect(() => {
        if (client) {
            Cookies.set('client', JSON.stringify(client), { expires: 7 });
        } else {
            Cookies.remove('client');
        }
    }, [client]);

    function producerRegistered () {
        navigate('/login');
    }

    function logout () {
        setUser(null);
        setLogin(null);
        setClient(null);
        navigate('/login');
    }

    function navOptionClicked (option) {
        log.debug("navOptionClicked");
        log.debug({option});
        if (option==="logout") {
            logout();
        } else {
            navigate(`/${option}`);
        }
    }

    // Determine the navigation bar based on user state
    let NavBarComponent = null;
    if (!user) {
        NavBarComponent = <LoginNavBar navOptionClicked={navOptionClicked} />;
    } else if (user.userType === 'USER_TYPE_ADMIN') {
        NavBarComponent = <AdminPortalNavBar navOptionClicked={navOptionClicked} />;
    } else if ((user.userType === 'USER_TYPE_PRODADMIN') || (user.userType === 'USER_TYPE_CLIENTADMIN')) {
        NavBarComponent = <ClientPortalNavBar navOptionClicked={navOptionClicked} />;
    } else if (user.userType === 'USER_TYPE_RECADMIN') {
        NavBarComponent = <ReceiverB2BNavBar navOptionClicked={navOptionClicked} />;
    }

    return (
        <AppContext.Provider value={{ user, setUser, login, setLogin, producer, setProducer, client, setClient }}>
            <main className="container position-relative bg-white">
                <div className='sticky-top bg-white'>
                    <Title />
                    {NavBarComponent}
                </div>
                <Routes>
                    <Route path="/activate/:userId/:activationCode" element={<ActivateUser />} />
                    <Route path="/login" element={<LoginScreen />} />
                    <Route path="/register" element={<RegisterClient producerRegistered={producerRegistered} />} />
                    <Route path="/portal/*" element={<ProtectedRoute allowedRoles={['USER_TYPE_PRODADMIN', 'USER_TYPE_CLIENTADMIN']} />}>
                        <Route path=":screenToShow" element={<UserPortal />} />
                    </Route>
                    <Route path="/admin-portal/*" element={<ProtectedRoute allowedRoles={['USER_TYPE_ADMIN']} />}>
                        <Route path=":screenToShow" element={<AdminPortal />} />
                    </Route>
                    <Route path="/receiver-portal" element={<ReceiverB2BPortal screenToShow={location.pathname.substring(1)} />} />
                </Routes>
                <div className='' style={{ height: '250px' }}></div>
                <Footer />
            </main>
        </AppContext.Provider>
    );
}
