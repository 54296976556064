import React, {useState, useContext} from 'react';
import {ProductExtraContext} from '../RegisterProduct';
import { ProductContext } from '../ShowProducts';
import FormLineInput from '../../layout/forms/FormLineInput';
import FormLineSelect from '../../layout/forms/FormLineSelect';
import resources from '../../../resources/lists/dropdownlist.json';
//import FormLineFileInput from '../../layout/forms/FormLineFileInput';
//import { CallOldCalculator } from '../../Calculator/CallCalculator';
import FormLineErrorMessage from '../../layout/forms/FormLineErrorMessage';
import {log} from '../../../Log';
import FormButton from '../../layout/forms/FormButton';
import PropTypes from 'prop-types';

RegisterWineBottleType.propTypes = {
    editMode: PropTypes.any, // Replace 'any' with the specific type you expect
    returnError: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function RegisterWineBottleType(props) {
    const {editMode,returnError} = props;
    const {bottleType,setBottleType} = useContext(ProductExtraContext);
    const {product} = useContext(ProductContext);
    const [brutPrice,setBrutoPrice] = useState(); // eslint-disable-line no-unused-vars
    const [error, setError] = useState({errorMessage: '',errorJSON: {}});

    function showError (errorMessage,errorJSON) {
        if (returnError) {
            returnError(errorMessage, errorJSON);
        } else { 
            setError({errorMessage: errorMessage, errorJSON: errorJSON});
        }
    }

    function getProductType () {
        if (product) {
            if (product.subType==="WINE_TYPE_STILL") {
                return ("PRODUCT_TYPE_BE_NONSPARKWINE");
            }
            else if (product.subType==="WINE_TYPE_SPARKLING") {
                return ("PRODUCT_TYPE_BE_SPARKWINE");
            }
            else {
                showError("Unknonw wine type", {wineType: product.subType});
                return ("ERROR");
            }
        } else {
            showError("Unknonw wine type",{});
            return ("ERROR");
        }
    }

    function calculateHandlerBT () {
        const productType = getProductType();
        if (productType==="ERROR") {
            return ("");
        }
        log.debug("ProductType : " + productType);
        const calcInput = {country: 'BE',productType: productType,degrees: product.alcoholPercentage, // eslint-disable-line no-unused-vars
        volume: bottleType.volume,packageType: bottleType.packageType,netPrice: bottleType.currentNetPrice};
/*        CallOldCalculator(calcInput,response => {
            if (response.err) {
                showError( response.err, calcInput);
            } else {
                setBrutoPrice(response.total)
            }
        });
        */
    }

    function changeHandler (e) {
        setBottleType({...bottleType,[e.name]: e.value});
    }

    return (
        <div className="RegisterProduct">
        <div className='row'>
            <FormLineInput col="2" maxLength={5} type="text" readOnly={!editMode} label="label.volume" name="volume" value={bottleType.volume} onChange={changeHandler} required={true}/>
            <FormLineInput col="2" maxLength={5} type="text" readOnly={!editMode} label="label.weight" name="weight" value={bottleType.weight} onChange={changeHandler} required={true}/>
            <FormLineInput col="4" maxLength={25} type="text" readOnly={!editMode} label="label.dimensions" name="dimensions" value={bottleType.dimensions} onChange={changeHandler} required={true}/>
        </div>
        <div className='row'>
            <FormLineSelect col="2" list={resources.packageTypes} readOnly={!editMode} label="label.packageType" name="packageType" value={bottleType.packageType} onChange={changeHandler} required={true}/>
            <FormLineInput col="2" maxLength={15} type="text" readOnly={!editMode} label="label.taric" name="taric" value={bottleType.taric} onChange={changeHandler} required={true}/>
        </div>
        <div className='row'>
            <FormLineInput col="2" maxLength={6} type="text" readOnly={!editMode} label="label.currentStock" name="currentStock" value={bottleType.currentStock} onChange={changeHandler} required={true}/>
            <FormLineInput col="2" maxLength={8} type="text" readOnly={!editMode} label="label.currentNetPrice" name="currentNetPrice" value={bottleType.currentNetPrice} onChange={changeHandler} required={true}/>
            <FormLineInput col="2" maxLength={8} type="text" readOnly={true} label="label.brutPrice" name="brutPrice" value={brutPrice}/>
            {
                editMode && <div className='col-md-2'><FormButton onClick={calculateHandlerBT} label='button.calculate'/></div>
            }
        </div>
        {!returnError && <FormLineErrorMessage message={error.errorMessage} errorJSON={error.errorJSON}/>}
    </div>
    )
}
/*
<div className='row'>
<FormLineFileInput label="label.uploadPicture" readOnly={!editMode} name="picture" value={bottleType.picture} onChange={changeHandler} required={true}/>
</div>
*/