import React, {useState,useContext} from 'react';
import resources from '../../resources/lists/dropdownlist.json';
import { AppContext } from '../Drinkereez';
import {log} from '../../Log';
import {CallSaveNationalCode} from './CallNationalCode'
import FormLineInput from '../layout/forms/FormLineInput';
import FormLineSelect from '../layout/forms/FormLineSelect';
import FormLineErrorMessage from '../layout/forms/FormLineErrorMessage';
import FormButton from '../layout/forms/FormButton';
import PropTypes from 'prop-types';

EditNationalCode.propTypes = {
    nationalCode: PropTypes.any, // Replace 'any' with the specific type you expect
    setNationalCode: PropTypes.any, // Replace 'any' with the specific type you expect
    stopShowNationalCode: PropTypes.any, // Replace 'any' with the specific type you expect
    nationalCodeSaved: PropTypes.any, // Replace 'any' with the specific type you expect
    editMode: PropTypes.any, // Replace 'any' with the specific type you expect
    setEditMode: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function EditNationalCode(props) {
    const {login} = useContext(AppContext);
    const {nationalCode,setNationalCode,stopShowNationalCode,nationalCodeSaved,editMode,setEditMode} = props;
    const [error, setError] = useState({errorMessage: '',errorJSON: {}});
    log.debug("in EditNationalCode");
    log.debug("editMode : " + editMode);
    log.debug(nationalCode);

    function changeHandler (e) {
        setNationalCode({...nationalCode,[e.name]: e.value});
    }

    function cancelHandler () {
        log.debug("in EditNationalCode - cancelHandler");
        stopShowNationalCode();
    }

    function editHandler () {
        log.debug("in EditNationalCode - editHandler");
        setEditMode(true);
    }

    function saveHandler () {
        log.debug("editNationalCode - saveHandler");

        CallSaveNationalCode(login,nationalCode,response => {
            if (response.err || response.ret?.err) {
                log.debug("error");
                setError({errorMessage: response.err + ' ' + response.ret?.err, errorJSON: {login,nationalCode}});
                return;
            }
            log.debug(response);
            nationalCodeSaved();           
        });
    }

    return (
        <div className='editNationalCode'>
            <div className='row'>
                <FormLineSelect col="4" list={resources.calculatorCountries} name="country" label="label.country" readOnly={true} value={nationalCode.country} onChange={changeHandler} required={true}/>
                <FormLineInput col="4" maxLength={50} type="text" readOnly={!editMode} label="label.natcode" name="natCode" value={nationalCode.natCode} onChange={changeHandler} required={true}/>
            </div>  
            <div className='row'>
                <FormLineSelect col="3" list={resources.generalProductTypes} name="productType" label="label.productType" readOnly={!editMode} value={nationalCode.productType} onChange={changeHandler} required={true}/>
                <FormLineSelect col="3" list={resources.sparklingTypes} name="sparklingType" label="label.sparklingType" readOnly={!editMode} value={nationalCode.sparklingType} onChange={changeHandler} required={true}/>
                <FormLineSelect col="3" list={resources.packageTypes} name="packageType" label="label.packageType" readOnly={!editMode} value={nationalCode.packageType} onChange={changeHandler} required={true}/>
            </div>  
            <div className='row'>
                <FormLineInput col="2" maxLength={20} type="text" readOnly={!editMode} label="label.abvFrom" name="abvFrom" value={nationalCode.abvFrom} onChange={changeHandler} required={true}/>
                <FormLineInput col="2" maxLength={20} type="text" readOnly={!editMode} label="label.abvTo" name="abvTo" value={nationalCode.abvTo} onChange={changeHandler} required={true}/>
            </div>  
            <div className='row'>
                <FormLineInput col="2" maxLength={20} type="text" readOnly={!editMode} label="label.productionHLFrom" name="productionHLFrom" value={nationalCode.productionHLFrom} onChange={changeHandler} required={true}/>
                <FormLineInput col="2" maxLength={20} type="text" readOnly={!editMode} label="label.productionHLTo" name="productionHLTo" value={nationalCode.productionHLTo} onChange={changeHandler} required={true}/>
            </div>  
            <div className='row'>
                <FormLineInput maxLength={1000} type="text" readOnly={!editMode} label="label.description" name="description" value={nationalCode.description} onChange={changeHandler} required={true}/>
            </div>
            <FormLineErrorMessage message={error.errorMessage} errorJSON={error.errorJSON}/>
            {
                !editMode && <FormButton onClick={editHandler} label='button.edit'/>
            }
            {
                editMode && <FormButton onClick={saveHandler} label='button.save'/>
            }
            <FormButton onClick={cancelHandler} level='secondary' label='button.cancel'/>
        </div>
    )
}