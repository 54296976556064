import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CallActivateUser } from './CallUser';
import { log } from '../../Log';
import FormLineErrorMessage from '../layout/forms/FormLineErrorMessage';
import FormLineSuccessMessage from '../layout/forms/FormLineSuccessMessage';

export default function ActivateUser() {
    const { userId, activationCode } = useParams();
    const [error, setError] = useState({ errorMessage: '', errorJSON: {} });
    const [result, setResult] = useState(0);

    useEffect(() => {
        if (userId && activationCode) {
            CallActivateUser(userId, activationCode, response => {
                if (response.err || response.ret?.err) {
                    log.debug("error");
                    setError({ errorMessage: response.err + ' ' + response.ret?.err, 
                        errorJSON: { errorMessage: response.err + ' ' + response.ret?.err, userId, activationCode } });
                    setResult(-1);
                } else {
                    setResult(1);
                    log.debug(response);
                }
            });
        }
    }, [userId, activationCode]);

    const text = result === 1 ? <FormLineSuccessMessage message="message.userActivated"/> : 
                 result === -1 ? <FormLineErrorMessage message="message.userActivationFailed" errorJSON={error.errorJSON}/> : "";

    return (
        <div className='activateUser'>
            {text}
        </div>
    );
}
