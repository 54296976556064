import {log} from '../Log';

export const useDomain = () => {
    const currentUrl = window.location.href;
    const start = currentUrl.indexOf('//') + 2;
    const end = currentUrl.indexOf('/', start);
    const domain = currentUrl.substring(start, end);
    log.debug("currentUrl : " + currentUrl + " " + domain);
    return domain;
};

export const getRecordsByVariableName = (recordList, variableName, targetValue) => {
    return recordList.filter(record => record[variableName] === targetValue);
  };

export function addOrUpdateRecord (table,newRecord) {
    if (newRecord.id) {
        const existingIndex = table.findIndex(record => record.id === newRecord.id);

        if (existingIndex !== -1) {
            const updatedRecords = [...table];
            updatedRecords[existingIndex] = newRecord;
            return updatedRecords;
        } else {
            return [...table, newRecord];
        }
    }
}

export function makeList (records,maxDisplayed) {
    const orderedList = records.slice().sort();
    const concatenatedIds = orderedList
      .slice(0, maxDisplayed)
      .map(record => record)
      .join(', ');
  
    const additionalIdsCount = orderedList.length - maxDisplayed;
    const additionalIdsText = additionalIdsCount > 0 ? `, ... ${additionalIdsCount} more` : '';
  
    return concatenatedIds + additionalIdsText;
}

export function createLinkedList(idList, totalList, dynamicFieldName) {
    const idToNameMap = {};
    if (!totalList) return [];
    totalList.forEach(record => {
      idToNameMap[record.id] = record[dynamicFieldName];
    });
  
    const linkedNames = idList.map(id => idToNameMap[id]);
    return linkedNames;
}

export function orderByField  (list, fieldName) {
    if (!list) return;
    const sortedRecords = [...list].sort((a, b) => {
        if (a[fieldName] < b[fieldName]) return -1;
        if (a[fieldName] > b[fieldName]) return 1;
        return 0;
      });
      return sortedRecords;
}

export function transformRecords(records, dynamicFieldName) {
    if (!records) return;
    return records.map(record => {
      return {
        value: record.id,
        label: record[dynamicFieldName],
      };
    });
}

export function extractValues(records, dynamicFieldName) {
  if (!records) return;
  return records.map(record => record[dynamicFieldName]);
}

export function getListByPercentage (list, percentage, difference) {
    const startIndex = Math.floor(list.length * (percentage - difference) / 100);
    const endIndex = Math.floor(list.length * percentage / 100);
    //log.debug("start : " + startIndex + " end : " + endIndex);
    return list.slice(startIndex, endIndex);
}

export function toNumber (text) {
    if (!text || text==="")
      return 0;
    return text;
}

export function GetLabelFromList (list,val) {
  /*let ret = '';

  list.map((element) => {
      if (element.value===value) {
          ret = element.label
      }
  });*/

  const record = list.find( ({value}) => value===val);

  return (record ? record.label : '');  
}

export function removePublicPrefixURL(url) {
  if (url.startsWith('public/')) {
      return url.slice(7); // Remove the first 7 characters
  }
  return url;
}