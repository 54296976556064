import React, { useContext } from 'react'
import { useTranslation } from "react-i18next";
import { AppContext } from '../Drinkereez';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
import TableLayout from '../layout/tables/TableLayout';
import TableRowCell from '../layout/tables/TableRowCell';
import resources from '../../resources/lists/dropdownlist.json'
import PropTypes from 'prop-types';

ClientServicesList.propTypes = {
    //navOptionClicked: PropTypes.func.isRequired // Expecting a function type
};

export default function ClientServicesList() {
    const { t } = useTranslation();
    const { client } = useContext(AppContext);
    const columns = ["label.service","label.explanation","label.contract","label.active"];

    function checkStatus(value, listOfStrings) {
        if (listOfStrings && listOfStrings.includes(value)) {
            return t("lists.drinkereezServiceStates.active");
        }
        return t("lists.drinkereezServiceStates.notActive");
    }

    /*<td>
    <a href="/resources/files/Importer_of_Record_for_Website.pdf" download="Importer_of_Record_for_Website.pdf">Download PDF</a>
    </td>*/

    function displayData () {
        const ret = resources.drinkereezServices.map((service) => {
            return (
                <tr key={service.value}>
                    <TableRowCell first="true" label={service.label} id={service.value}/>
                    <TableRowCell label={service.text}/>
                    <TableRowCell label="label.contract" onClick={() => window.open(service.url, '_blank')}/>
                    <TableRowCell value={checkStatus(service.value,client.services)}/>
                </tr>
            )
        }) 
        return (ret);
    }

    return (
        <div>
            <div className='row'>
                <FormLineParagHeader col='3' label={"title.serviceRequestList"}/>
            </div>
            <TableLayout columns={columns} displayData={displayData}/>
        </div>
    )
}