import React from 'react';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';

TableHeaderCell.propTypes = {
    value: PropTypes.any, // Replace 'any' with the specific type you expect
    label: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function TableHeaderCell (props) {
    const {t} = useTranslation();
    const {value,label} = props;
    const text = value ? value : t(label);

    return (
        <th scope='col'> {text} </th>
    );  
}