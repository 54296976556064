import React,{useState,useContext,useEffect} from 'react';
import { AppContext } from '../Drinkereez';
import { CallGetAllIE801 } from './CallExcise';
import {log} from '../../Log';
//import { useTranslation } from "react-i18next";
import TableRowCell from '../layout/tables/TableRowCell';
import TableLayout from '../layout/tables/TableLayout';
import FormLineParagHeader from '../layout/forms/FormLineParagHeader';
import PropTypes from 'prop-types';

ListIE801.propTypes = {
    viewDoc: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function ListIE801(props) {   
    const {viewDoc} = props; 
    //const {t} = useTranslation();
    const {login} = useContext(AppContext);
    const columns = ["label.referenceCodeEAD","label.consignor","label.consignee","label.transportUnitId"];
    const [ie801s,setIe801s] = useState ();

    useEffect(() => {
        getAllIE801s();
      }, []);

    function getAllIE801s() {
        log.debug("ListIE801 - getAllIE801s");
        CallGetAllIE801(login,response => {
            if (response.err || response.ret?.err) {
                log.debug("error");
                return;
            }
            log.debug(response);
            setIe801s(response.ie801s);
        });
    }  

    function onClickTable (e) {
        log.debug("clicked : " + e.currentTarget.id);
        viewDoc(ie801s.find(({id}) => id === e.currentTarget.id));
    }

    function displayData () {
        if (!ie801s) return('');
        const ret = ie801s.map((ie801) => {
            return (
                <tr key={ie801.id}>
                    <TableRowCell first="true" value={ie801.referenceCodeEAD} onClick={onClickTable} id={ie801.id}/>
                    <TableRowCell value={ie801.consignorTrader.name}/>
                    <TableRowCell value={ie801.consigneeTrader.name}/>
                    <TableRowCell value={ie801.transportUnitId}/>
                </tr>
            )
        }) 
        return (ret);
    }


    return (
        <div className="ListIE801">
            <div className='row'>
                <FormLineParagHeader col='3' label={"title.ie801s"}/>
            </div>
            <TableLayout columns={columns} displayData={displayData}/>
        </div>
    );
}