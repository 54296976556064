import React, {useState, useContext} from 'react'
import { AppContext } from '../Drinkereez';
import FormLineInput from '../layout/forms/FormLineInput';
import FormLineErrorMessage from '../layout/forms/FormLineErrorMessage';
import FormButton from '../layout/forms/FormButton';
import { CallCheckUserNamePassword } from './CallUser';
import { CallGetClientFromUser } from '../Client/CallClient';
import {log} from '../../Log'; 

export default function LoginScreen() {
    const {setLogin, setUser, setClient} = useContext(AppContext);

    const [logon, setLogon] = useState({username: '',password: ''});
    const [error, setError] = useState({errorMessage: '',errorJSON: {}});

    function changeHandler (e) {
        const changes = {[e.name]: e.value};
        setLogon({...logon,...changes});
    }

    function doReset () {
        setLogon({username: '',password: ''});
        setError({errorMessage: '',errorJSON: {}});
    }

    function submitHandler (e) {
        setError({errorMessage: '',errorJSON: {}});
        e.preventDefault();

        log.debug("execute await statements");
        CallCheckUserNamePassword(logon.username,logon.password,response => {
            if (response.err) {
                setError({errorMessage: response.err, errorJSON:{}});
            } else {
                const user = response;
                const login = {usr: user.id, pwd: logon.password};
                if (user.userType!=="USER_TYPE_ADMIN") CallGetClientFromUser(login,resp => {
                    if (resp.err) {
                        setError({errorMessage: resp.err, errorJSON:{}});
                    } else {
                        setLogin({...login});
                        setUser({...user});
                        setClient({...resp});
                        doReset();
                    }
                });
                else {
                    setLogin({...login});
                    setUser({...user});
                    doReset();                    
                }
            }            
        });
    }

    return (
        <div className='mb-3'>
            <div className='row'>
                <FormLineInput col={5} type="input" label="label.username" name="username" value={logon.username} onChange={changeHandler} required={true}/>
            </div>
            <div className='row'>
                <FormLineInput col={5} type="password" label="label.password" name="password" value={logon.password} onChange={changeHandler} required={true}/>
            </div>
            <FormLineErrorMessage message={error.errorMessage} errorJSON={error.errorJSON}/>
            <FormButton type="submit" onClick={submitHandler} label='button.submit'/>
            <FormButton type="reset" onClick={doReset} label='button.reset' level='secondary'/>
        </div>
    )
}
