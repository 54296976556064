import React,{useState,useContext,useEffect} from 'react';
import { AppContext } from '../Drinkereez';
import {log} from '../../Log';
import ShowNationalCodes from './ShowNationalCodes';
import EditNationalCode from './EditNationalCode';
import ShowTarifNationalCodes from './ShowTarifNationalCodes';
import EditTarifNationalCode from './EditTarifNationalCode';
import ShowLinkNatCodeEUCode from './ShowLinkNatCodeEUCode';
import { CallgetAllNationalCodes , CallgetAllTarifNationalCodes, CallgetAllLinksNatCodeEUCode } from './CallNationalCode';
import { addOrUpdateRecord,orderByField } from '../GeneralFunc';
import PropTypes from 'prop-types';

NationalCodePage.propTypes = {
    screen: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function NationalCodePage (props) {
    const {screen} = props;
    const [nationalCode,setNationalCode] = useState();
    const {login} = useContext(AppContext);
    const [tarif,setTarif] = useState({country:"BE",tarifType:"",calculationBase:"",value:"",natCodes:[]});
    const [editMode, setEditMode] = useState(false);
    const [showScreen, setShowScreen] = useState(screen);
    const [prevScreen, setPrevScreen] = useState("");
    const [nationalCodes,setNationalCodes] = useState ();
    const [tarifs,setTarifs] = useState ();
    const [linksNatCodeEUCode,setLinksNatCodeEUCode] = useState();
    const [forceRender, setForceRender] = useState(false); // eslint-disable-line no-unused-vars
    log.debug("in NationalCodePage");

    useEffect(() => {
        getAllNationalCodes();
        getAllTarifNationalCodes();
        getAllLinksNatCodeEUCode();
    }, []);

    useEffect(() => { 
        log.debug("NationalCodePage updated");
        setForceRender(prevValue => !prevValue); 
    },[nationalCode,tarif,linksNatCodeEUCode]);

    function getAllNationalCodes() {
        log.debug("NationalCodePage - getAllNationalCodes");
        CallgetAllNationalCodes(login,response => {
            if (response.err || response.ret?.err) {
                log.debug("error");
                return;
            }
            log.debug(response);
            log.debug("NationalCodePage - setNationalCodes");
            setNationalCodes(response.natCodes);
        });
    }  

    function getAllTarifNationalCodes() {
        log.debug("NationalCodePage - getAllTarifNationalCodes");
        CallgetAllTarifNationalCodes(login,response => {
            if (response.err || response.ret?.err) {
                log.debug("error");
                return;
            }
            log.debug(response);
            log.debug("NationalCodePage - setTarifs");
            setTarifs(orderByField(response.tarifNatCodes,"tarifType"));
        });
    } 

    function getAllLinksNatCodeEUCode() {
        log.debug("NationalCodePage - getAllLinksNatCodeEUCode");
        CallgetAllLinksNatCodeEUCode(login,response => {
            if (response.err || response.ret?.err) {
                log.debug("error");
                return;
            }
            log.debug(response);
            log.debug("NationalCodePage - setLinksNatCodeEUCode");
            log.debug(response.natCodeEUCodes);
            setLinksNatCodeEUCode(response.natCodeEUCodes,"tarifType");
        });
    } 

    function nationalCodeSelected (natCode) {
        log.debug("in NationalCodePage - nationalCodeSelected");
        log.debug(natCode);
        setNationalCode(natCode);  
        setPrevScreen(showScreen);
        setShowScreen("natCodeEdit");
    }

    function stopShowNationalCode () {
        log.debug("in NationalCodePage - stopShowNationalCode");
        setShowScreen(prevScreen);
        setPrevScreen("");
    }

    function nationalCodeSaved (natCode) {
        log.debug("in NationalCodePage - nationalCodeSaved");
        addOrUpdateNatCode(natCode);
        stopShowNationalCode();
    }

    const addOrUpdateNatCode = (newRecord) => {
        setNationalCodes(addOrUpdateRecord(nationalCodes,newRecord));
    };

    const addOrUpdateTarif = (newRecord) => {
        setTarifs(addOrUpdateRecord(tarifs,newRecord));
    };

    const addOrUpdateLinkNatCodeEUCode = (newRecord) => {
        setLinksNatCodeEUCode(addOrUpdateRecord(linksNatCodeEUCode,newRecord));
    };

    function tarifSelected (tar) {
        log.debug("in TarifNationalCodePage - tarifSelected");
        log.debug(tar);
        setTarif(tar);  
        setPrevScreen(showScreen);
        setShowScreen("tarifEdit");
        setEditMode(false);
    }

    function addTarif () {
        log.debug("in TarifNationalCodePage - addTarif");
        setEditMode(true);
        log.debug("editMode :  "  + editMode);
        setTarif({country:"BE",tarifType:"",calculationBase:"",value:"",natCodes:[]});
        setPrevScreen(showScreen);
        setShowScreen("tarifEdit");
    }

    function stopShowTarif () {
        log.debug("in TarifNationalCodePage - stopShowTarif");
        setShowScreen(prevScreen);
        setPrevScreen("");
    }

    function tarifSaved (tar) {
        log.debug("in TarifNationalCodePage - tarifSaved");
        addOrUpdateTarif(tar);
        stopShowTarif();
    }

    return (
        <div className="ShowNationalCode">
            {
                (showScreen === "natCodeList") && <ShowNationalCodes nationalCodeSelected={nationalCodeSelected} nationalCodes={nationalCodes} addOrUpdateNatCode={addOrUpdateNatCode}/>
            }
            {
                (showScreen === "natCodeEdit") && <EditNationalCode nationalCode={nationalCode} setNationalCode={setNationalCode} stopShowNationalCode={stopShowNationalCode} nationalCodeSaved={nationalCodeSaved}/>
            }
            {
                (showScreen === "tarifList") && <ShowTarifNationalCodes tarifSelected={tarifSelected} addTarif={addTarif} tarifs={tarifs} natCodes={nationalCodes}/>
            }
            {
                (showScreen === "tarifEdit") && <EditTarifNationalCode tarif={tarif} setTarif={setTarif} stopShowTarif={stopShowTarif} tarifSaved={tarifSaved} editMode={editMode} setEditMode={setEditMode} natCodes={nationalCodes}/>
            }
            {
                (showScreen === "linkNatCodeEUCodeList") && <ShowLinkNatCodeEUCode linksNatCodeEUCode={linksNatCodeEUCode} addOrUpdateLinkNatCodeEUCode={addOrUpdateLinkNatCodeEUCode} natCodes={nationalCodes}/>
            }
        </div>
    )
}