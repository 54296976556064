import React from 'react';
import Drinkereez from './component/Drinkereez';
import { BrowserRouter as Router } from 'react-router-dom';

function App () {
    return (
      <Router>
          <Drinkereez/>
      </Router>
    );
  }

export default App;