import React,{useState} from 'react';
//import { AppContext } from '../Drinkereez';
import ShowOrders from './ShowOrders';

export default function ProducerPage() {
    const [order,setOrder] = useState(); // eslint-disable-line no-unused-vars
    const [showOrderList, setShowOrderList] = useState(true);
    const [showOrder,setShowOrder] = useState(false); // eslint-disable-line no-unused-vars

    function orderSelected (prod) {
        setShowOrderList(false);
        setShowOrder(true);
        setOrder(prod);       
    }

    function stopShowOrder () {
        setShowOrderList(true);
        setShowOrder(false);
    }

    function orderSaved () {  // eslint-disable-line no-unused-vars
        //getAllProducers();
        stopShowOrder();
    }

    return (
        <div className="ShowOrder">
            {
                showOrderList && <ShowOrders orderSelected={orderSelected}/>
            }
        </div>
    )
}
