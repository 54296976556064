import React, { useState } from 'react'
import CalculatorPostForm from './CalculatorPostForm';
import CalculatorResponsScreen from './CalculatorResponsScreen';

export default function Calculator() {
    const [calcRet, setCalcRet] = useState({});

    function fillReturnCalc (calcRet) {
        setCalcRet(calcRet);
    }

    return (
        <>
            <CalculatorPostForm fillReturnCalc={fillReturnCalc}/> 
            <CalculatorResponsScreen {...calcRet}/>
        </>
    )
}
