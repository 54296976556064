import React from 'react';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';

TableRowCell.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string, 
    value: PropTypes.string,
    onClick: PropTypes.func
};

export default function TableRowCell({ id, label, value, onClick }) {
    const { t } = useTranslation();
    const text = value || t(label);  // Use translation only if value is not provided

    // Render as link if onClick provided, otherwise as plain text
    return (
        <td>
            {onClick ? (<a onClick={onClick} id={id} className="link-dark text-decoration-none" href="#nogo">{text}</a>) : (text)}
        </td>
    );
}

/*
import React from 'react';
import { useTranslation } from "react-i18next";
//import { log } from '../../../Log';
import PropTypes from 'prop-types';

TableRowCell.propTypes = {
    id: PropTypes.any, // Replace 'any' with the specific type you expect
    label: PropTypes.any, // Replace 'any' with the specific type you expect
    value: PropTypes.any, // Replace 'any' with the specific type you expect
    onClick: PropTypes.any, // Replace 'any' with the specific type you expect
    first: PropTypes.any // Replace 'any' with the specific type you expect
};

export default function TableRowCell (props) {
    const {t} = useTranslation();
    const {id,label,value,onClick,first} = props;
    const text = value ? value : t(label);

    if (onClick) return (
            first ? <td><a onClick={onClick} id={id} className='link-dark text-decoration-none' href='#nogo'> {text} </a></td> : 
                    <td><a onClick={onClick} id={id} className='link-dark text-decoration-none' href='#nogo'> {text} </a></td>);
    else return (first ? <td> {text} </td> : 
                         <td> {text} </td>);
}*/