import React from 'react'
import FormLineInput from '../layout/forms/FormLineInput'
import PropTypes from 'prop-types';

CalculatorResponsScreen.propTypes = {
    netPrice: PropTypes.any, // Replace 'any' with the specific type you expect
    excise: PropTypes.any, // Replace 'any' with the specific type you expect
    specialExcise: PropTypes.any, // Replace 'any' with the specific type you expect
    packageTax: PropTypes.any, // Replace 'any' with the specific type you expect
    commision: PropTypes.any, // Replace 'any' with the specific type you expect
    VAT: PropTypes.any, // Replace 'any' with the specific type you expect
    total: PropTypes.any // Replace 'any' with the specific type you expect
};
export default function CalculatorResponsScreen(props) {
    const {netPrice,excise,specialExcise,packageTax,commision,VAT,total} = props;
    const duty = excise + specialExcise + packageTax;

  return (
    <div>
        <div className='row'>
            <FormLineInput col="3" type='text' label="label.netPrice" name="netPrice" value={netPrice} readOnly={false}/>
        </div>
        <div className='row'>
            <FormLineInput col="3" type='text' label="label.excise" name="excise" value={excise} readOnly={false}/>
            <FormLineInput col="3" type='text' label="label.specialExcise" name="specialExcise" value={specialExcise} readOnly={false}/>
            <FormLineInput col="3" type='text' label="label.packageTax" name="packageTax" value={packageTax} readOnly={false}/>
            <FormLineInput col="3" type='text' label="label.duty" name="duty" value={duty} readOnly={false}/>
        </div>
        <div className='row'>
            <FormLineInput col="3" type='text' label="label.commision" name="commision" value={commision} readOnly={false}/>
            <FormLineInput col="3" type='text' label="label.VAT" name="VAT" value={VAT} readOnly={false}/>
        </div>
        <div className='row'>
            <FormLineInput col="3" type='text' label="label.totalPrice" name="totalPrice" value={total} readOnly={false}/>
        </div>
    </div>
  )
}
