import React,{useState} from 'react';
//import { AppContext } from '../Drinkereez';
import FormLineSelect from '../layout/forms/FormLineSelect';
import resources from '../../resources/lists/dropdownlist.json'
import ListIE801 from './ListIE801';
import ShowIE801 from './ShowIE801';
import {log} from '../../Log';

export default function ExcisePage() {
    const [documentType,setDocumentType] = useState ();
    const [exciseDoc,setExciseDoc] = useState();
    const [viewPage,setViewPage] = useState('EMPTY');

    function onChangeTypeHandler(e) {
        setDocumentType(e.value);
        log.debug("list : " + e.value);
        if (e.value==="EXCISE_XML_TYPE_IE801") setViewPage('LIST_IE801');
    }

    function viewDoc (selectedDoc) {
        log.debug("view doc : " + documentType);
        if (selectedDoc && documentType==="EXCISE_XML_TYPE_IE801") {
            setExciseDoc(selectedDoc);
            setViewPage('DOC_IE801');
        }
        log.debug("view page : " + viewPage);
    }

    function cancelViewDoc () {
        if (documentType==="EXCISE_XML_TYPE_IE801") setViewPage('LIST_IE801');
    }

    return (
            <div className="ShowExcise">
                <FormLineSelect type="input" name="documentType" value={documentType} label="label.type" onChange={onChangeTypeHandler} list={resources.ExciseXMLTypes}/>
                {
                    viewPage==='LIST_IE801' && <ListIE801 viewDoc={viewDoc}/>
                }
                {
                    viewPage==='DOC_IE801' && <ShowIE801 exciseDoc={exciseDoc} cancelViewDoc={cancelViewDoc}/>
                }
            </div>
    )
}
