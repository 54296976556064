import React from 'react';
import { useTranslation } from "react-i18next";
import { log } from '../../../Log';
import PropTypes from 'prop-types';

FormLineFileInput.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    setFile: PropTypes.func.isRequired
};

export default function FormLineFileInput ({ label, name, readOnly, required, setFile }) {
    const { t } = useTranslation();

    function uploadFile(e) {
        const file = e.target.files[0];
        if (file) {
            log.debug(file);
            setFile(file);
        }
    }

    return (
        <div className="form-group row my-2">
            <label className="col-sm-3 col-form-label text-md-left text-dark">{t(label)}</label>
            <div className="col-sm-9">
                <input 
                    type="file"
                    className="form-control"
                    readOnly={readOnly}
                    onChange={uploadFile}
                    required={required}
                    // The `value` prop on file input is read-only and setting it can cause an error.
                    // Removed the `value` prop as it's not valid for file inputs and can lead to warnings/errors.
                />
            </div>
        </div>
    );
}

/*
import React from 'react';
import { useTranslation } from "react-i18next";
import {log} from '../../../Log';
import PropTypes from 'prop-types';

FormLineFileInput.propTypes = {
    label: PropTypes.any, // Replace 'any' with the specific type you expect
    value: PropTypes.any, // Replace 'any' with the specific type you expect
    subdrirectory: PropTypes.any, // Replace 'any' with the specific type you expect
    readOnly: PropTypes.any, // Replace 'any' with the specific type you expect
    required: PropTypes.any, // Replace 'any' with the specific type you expect
    file: PropTypes.any, // Replace 'any' with the specific type you expect
    setFile: PropTypes.func // Replace 'any' with the specific type you expect
};

export default function FormLineFileInput (props) {
    const {t} = useTranslation();
    const {label,value,readOnly,required,setFile} = props;
    const req = required && required.toUpperCase()==="TRUE";

    function uploadFile (e) {
        log.debug(e.target.files[0]);
        setFile(e.target.files[0]);
        //ret.value = e.target.files[0];
        //props.onChange(ret);    
    }

    return (
        <div className="form-group row my-2">
            <label className="col-sm-3 col-form-label text-md-left text-dark">{t(label)}</label>
            <div className="col-sm-6">
                <input type="file" readOnly={readOnly} className="form-control" name="value" value={value} onChange={uploadFile} required={req}/>
            </div>
        </div>
    );  
}*/