import React from 'react';
import settings from '../../../resources/generalSettings.json';
import TableHeader from './TableHeader';
import PropTypes from 'prop-types';

TableLayout.propTypes = {
    displayData: PropTypes.func.isRequired, // It's expected to be a function returning JSX.
    columns: PropTypes.arrayOf(PropTypes.string)
    /*columns: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        title: PropTypes.string.isRequired,
        dataIndex: PropTypes.string.isRequired,
        render: PropTypes.func // Optional renderer function for complex columns.
    })).isRequired // Expect an array of objects describing columns.*/
};

export default function TableLayout({ displayData, columns }) {
    return (
        <table className={settings.tableClassName}>
            <TableHeader columns={columns} />
            <tbody className={settings.tableBodyClassName}>
                {displayData()} 
            </tbody>
        </table>
    );
}
