import {useCallBackend } from '../General/CallBackend';
import {log} from '../../Log';
import nationalCodeTest from '../../resources/test/nationalCodeTest.json';
import { v4 as uuidv4 } from 'uuid';

export function CallSaveNationalCode (login,nationalCode,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';

    log.debug("send JSON CallSaveNationalCode");

    log.debug(nationalCode);

    const body = { 
        req : {...login,action: 2},
        ...nationalCode
    }

    log.debug(body);
    log.debug("post");

    if (testMode) {
        const uid = (nationalCode.id==="0") ? uuidv4() : nationalCode.id
        const response = {...nationalCode,id:uid}
        responseFunc(response);

    } else {
        CallBackend ("NationalCode",body, response => {
            responseFunc(response);
        });
    } 
}

export function CallgetAllNationalCodes (login,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';
    log.debug("send JSON CallgetAllNationalCodes");

    if (testMode) {
        //responseFunc ({err : "error.server_return_error"});
        responseFunc (nationalCodeTest.natCodes);
    }
    else {
        const body = { 
            req : {...login,action: 3}
        }

        log.debug(body);
        log.debug("post");

        CallBackend ("NationalCode",body, response => {
            responseFunc(response);
        });
    }
}

export function CallSaveTarifNationalCode (login,tarif,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';

    log.debug("send JSON CallSaveTarifNationalCode");

    const body = { 
        req : {...login,action: 4},
        ...tarif
    }

    log.debug(body);
    log.debug("post");

    if (testMode) {
        responseFunc(body);
    } else {
        CallBackend ("NationalCode",body, response => {
            responseFunc(response);
        });
    } 
}

export function CallgetAllTarifNationalCodes (login,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';
    log.debug("send JSON CallgetAllTarifNationalCodes");

    if (testMode) {
        //responseFunc ({err : "error.server_return_error"});
        responseFunc (nationalCodeTest.tarifNatCodes);
    }
    else {
        const body = { 
            req : {...login,action: 5}
        }

        log.debug(body);
        log.debug("post");

        CallBackend ("NationalCode",body, response => {
            responseFunc(response);
        });
    }
}

export function CallgetTarifForNationalCode (login,id,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';
    log.debug("send JSON CallgetTarifForNationalCode");

    const body = { 
        req : {...login,action: 8,id:id}
    }

    log.debug(body);
    log.debug("post");

    if (testMode) {
        //responseFunc ({err : "error.server_return_error"});
        responseFunc (nationalCodeTest.tarifsNatCode);
    }
    else {
        CallBackend ("NationalCode",body, response => {
            responseFunc(response);
        });
    }
}


export function CallgetAllLinksNatCodeEUCode (login,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';
    log.debug("send JSON CallgetAllLinksNatCodeEUCode");

    if (testMode) {
        //responseFunc ({err : "error.server_return_error"});
        responseFunc (nationalCodeTest.natCodeEUCodes);
    }
    else {
        const body = { 
            req : {...login,action: 7}
        }

        log.debug(body);
        log.debug("post");

        CallBackend ("NationalCode",body, response => {
            responseFunc(response);
        });
    }
}

export function CallSaveLinkNatCodeEUCode (login,linkNatCodeEUCode,responseFunc) {
    const { CallBackend } = useCallBackend();
    const testMode = process.env.REACT_APP_TESTMODE === 'Y';

    log.debug("send JSON CallSaveLinkNatCodeEUCode");

    log.debug(linkNatCodeEUCode);

    const body = { 
        req : {...login,action: 6},
        ...linkNatCodeEUCode
    }

    log.debug(body);
    log.debug("post");

    if (testMode) {
        const uid = (linkNatCodeEUCode.id==="0") ? uuidv4() : linkNatCodeEUCode.id
        const response = {...linkNatCodeEUCode,id:uid}
        responseFunc(response);

    } else {
        CallBackend ("NationalCode",body, response => {
            responseFunc(response);
        });
    } 
}